import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    display: "flex",
    backgroundColor: "white",
    borderTop: "3mm ridge #90d058",
    justifyContent: "space-around",
    padding: "0.3rem",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      "&:before": {
        content: "''",
        height: "30px",
      },
    },
  },
  item: {
    display: "block",
    transition: "opacity 1s ease-in-out",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: "0.3rem",
      left: "50%",
      transform: "translateX(-50%)",
      transition: "opacity 1s ease-in-out",
      animation: "sponsorAnimation 48s infinite",
      opacity: 0,
      "&:nth-child(1)": {
        animationDelay: "0s",
      },
      "&:nth-child(2)": {
        animationDelay: "12s",
      },
      "&:nth-child(3)": {
        animationDelay: "24s",
      },
      "&:nth-child(4)": {
        animationDelay: "36s",
      },
    },
  },
  img: {
    height: "40px",
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
  },
}));

function SponsorLogo({ url, alt }) {
  const classes = useStyles();
  return (
    <li className={classes.item}>
      <img className={classes.img} src={url} alt={alt} />
    </li>
  );
}

export default function SponsorList({ style }) {
  const classes = useStyles();
  return (
    <ul className={classes.container} style={style}>
      <SponsorLogo
        url="/partenaires/DLA-logo.png"
        alt="Notre partenaire : DLA"
      />
      <SponsorLogo
        url="/partenaires/secretariat-d-etat.png"
        alt="Notre partenaire : secrétariat d'état chargé de l'économie sociale et solidaire et de la vie associative"
      />
      <SponsorLogo
        url="/partenaires/banque-des-territoires-logo.jpg"
        alt="Notre partenaire : Banque des territoires"
      />
      <SponsorLogo
        url="/partenaires/cofinance-par-UE-logo.png"
        alt="Notre partenaire : U-E"
      />
    </ul>
  );
}
