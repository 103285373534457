import React from "react";
import {
  ListItem,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteButton from "../../../common/forms/DeleteButton";

export default function TutorialItem({
  tutorial,
  updateTutorial,
  deleteTutorial,
  divider,
}) {
  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="h5">{tutorial.tutorialName}</Typography>
          }
          secondary={
            <Box color="grey.darkGrey">
              <Typography variant="body1">
                {tutorial.tutorialDescription === "" ? (
                  <></>
                ) : (
                  <>
                    <i>{tutorial.tutorialDescription}</i>
                    <br />
                  </>
                )}
                <a
                  href={tutorial.tutorialLink}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tutorial.tutorialLink}
                </a>
              </Typography>
            </Box>
          }
          style={{ marginRight: "30px" }}
        />
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            edge="end"
            onClick={() => updateTutorial(tutorial)}
          >
            <EditIcon />
          </IconButton>
          <DeleteButton
            deleteElement={() => deleteTutorial(tutorial)}
            elementTitre={tutorial.tutorialName}
          />
        </ListItemSecondaryAction>
      </ListItem>

      {divider && <Divider component="li" />}
    </>
  );
}
