import React from "react";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import Container from "@material-ui/core/Container";

export function PageContentWrapper({ children }) {
  const { width } = useWindowDimensions();
  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Container
      style={{
        marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
        marginBottom: isWindowSizeBelowSm() ? "110px" : "190px",
      }}
    >
      {children}
    </Container>
  );
}
