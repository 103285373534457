import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function ExistantIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.30313 18.6918C9.69121 19.0689 10.3089 19.0689 10.6969 18.6918L13.4366 16.0295L18 16.0295C19.1046 16.0295 20 15.1341 20 14.0295L20 1.99995C20 0.895376 19.1046 -5.33706e-05 18 -5.33272e-05L2 -5.26987e-05C0.895426 -5.26553e-05 -3.51738e-06 0.895379 -3.30248e-06 1.99995L-9.62091e-07 14.0295C-7.47193e-07 15.1341 0.895431 16.0295 2 16.0295L6.56342 16.0295L9.30313 18.6918Z"
      />
      <path
        d="M9.84798 3.20569C7.11658 3.20569 4.89978 5.59943 4.89978 8.54887C4.89978 11.4983 7.11658 13.8921 9.84799 13.8921C12.5794 13.8921 14.7962 11.4983 14.7962 8.54887C14.7962 5.59943 12.5794 3.20569 9.84798 3.20569ZM8.85834 11.2205L6.38424 8.54887L7.08194 7.79548L8.85834 9.70834L12.614 5.65287L13.3117 6.4116L8.85834 11.2205Z"
        fill="white"
      />
    </SvgIcon>
  );
}
