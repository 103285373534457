import React from "react";
import { Grid, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  cssLabel: {
    color: "#90d058 !important",
  },
  floatingLabelFocusStyle: {
    color: "#90d058 !important",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#90d058 !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
  },
}));

export default function MyTextField(props) {
  const classes = useStyles();

  return (
    <Grid item container xs={12} sm={10}>
      <TextField
        {...props}
        type="text"
        fullWidth
        variant="outlined"
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
          className: classes.floatingLabelFocusStyle,
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputMode: "numeric",
        }}
      />
    </Grid>
  );
}
