import React, { useState, useEffect } from "react";
import { Box, Paper, List, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import IndividualResultsItem from "./IndividualResultsItem";
import MySnackbar from "../../common/MySnackbar";

import UserResponsesService from "../../../APIrequests/userResponseRequests";
import UserService from "../../../APIrequests/userRequests";
import Loader from "../../common/Loader";
import MyBackdrop from "../../common/MyBackdrop";

export default function IndividualResultsList() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userResponses, setUserResponses] = useState([]);
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    setIsLoaded(false);
    UserResponsesService.getPaginateList(actualPage, 25)
      .then(({ userResponses, totalPages }) => {
        setUserResponses(userResponses);
        setTotalPages(totalPages);
      })
      .catch((erreur) => console.log(erreur))
      .finally(() => setIsLoaded(true));
  }, [actualPage]);

  /**
   * Supprime la réponse passée en paramètre de la base de données puis en local.
   * @param {*} userResponseToDelete
   */
  const deleteUserResponse = async (userResponseToDelete) => {
    setBackdropOpen(true);

    try {
      const result = await UserService.deleteUser(
        userResponseToDelete.user._id
      );

      if (result.data.text !== "Erreur serveur" && result.status === 200) {
        setUserResponses([
          ...userResponses.filter(
            (myuserResponse) => myuserResponse._id !== userResponseToDelete._id
          ),
        ]);
      }
      setSnackbarMessage(result.data.text);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue.");
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Paper
      elevation={5}
      style={{
        margin: "auto",
        padding: "28px 28px 28px 28px",
        width: "100%",
      }}
    >
      <MySnackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <MyBackdrop open={backdropOpen} />
      {!isLoaded ? (
        <Loader height="400px" />
      ) : (
        <>
          <Typography component="h4" variant="h4">
            Réponses individuelles
          </Typography>

          <List>
            {userResponses.reverse().map((userResponse, index) => {
              return (
                <IndividualResultsItem
                  key={index}
                  userResponse={userResponse}
                  deleteUserResponse={deleteUserResponse}
                  divider={index < userResponses.length - 1}
                />
              );
            })}
          </List>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Pagination
              count={totalPages}
              page={actualPage}
              onChange={(event, newPage) => setActualPage(newPage)}
            />
          </Box>
        </>
      )}
    </Paper>
  );
}
