import React, { useEffect, useState } from "react"
import { TextField, makeStyles, InputAdornment } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    cssLabel: {
      color: '#90d058 !important'
    },
    floatingLabelFocusStyle: {
        color: '#90d058 !important'
    },
    cssFocused: {
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: '#90d058 !important',
      }
    },
    notchedOutline: {
      borderWidth: '1px',
    },
  })
);


export default function MyTextFieldLogIn(props) {
    var [error, setError] = useState(props.error);

    const classes = useStyles();

    var helperText;

    //switch parcouru à chaque render car le useEffect sur l'état error qui écoute la prop error vient forcer un render du composant
    switch (props.id) {
        case "email":
            helperText = "Un email est requis pour vous connecter";
            break
        case "password":
            if (props.wrongPasswordError) {
                helperText = "Mot de passe incorrect, ou l'utilisateur n'existe pas"
            } else {
                helperText = "Un mot de passe est requis pour vous connecter";
            };
            break
        default:
            break
    }


    useEffect(()=> {
        setError(props.error)
    }, [props.error])


    return (
        <TextField
            id={props.id}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            autoFocus={props.autoFocus}
            name={props.name}
            type={props.type}
            fullWidth
            helperText={error ? props.helperText || helperText: ""}
            error={error}
            variant="outlined"
            InputLabelProps={{
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                },
                className: classes.floatingLabelFocusStyle
            }}
            InputProps={{
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric",
                endAdornment: (
                      <InputAdornment>
                        {props.inputIcon}
                      </InputAdornment>
                    )
            }}
        />
    )
}
