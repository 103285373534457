import React from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

export function MainFormPartialDiagnosticModal({ theme, description }) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="quick-form-explanation">{theme}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="quick-form-explanation-text">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Je lance mon diagnostic
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
