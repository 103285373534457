import React, { useEffect } from "react";
import {
  Redirect,
  Switch,
  Route,
  withRouter /*, BrowserRouter*/,
} from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { createTheme, ThemeProvider } from "@material-ui/core";

import PublicRoute from "./components/routers/PublicRoute";
import AdminRoute from "./components/routers/AdminRoute";
import SimpleAuthRoute from "./components/routers/SimpleAuthRoute";
import PrivateRoute from "./components/routers/PrivateRoute";

import FormPage from "./components/pages/FormPage";
import QuickDiagnosticPage from "./components/pages/QuickDiagnosticPage";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import ResetPasswordPage from "./components/pages/ResetPasswordPage";
import EditProductsPage from "./components/pages/EditProductsPage";
import EditProfilePage from "./components/pages/EditProfilePage";
import EditQuestionsPage from "./components/pages/EditQuestionsPage";
import EditTutorialsPage from "./components/pages/EditTutorialsPage";
import ResultsAnalysisPage from "./components/pages/ResultsAnalysisPage";
import ResultsPage from "./components/pages/ResultsPage";
import SignupPage from "./components/pages/SignupPage";
import WelcomePage from "./components/pages/WelcomePage";
import DashboardPage from "./components/pages/Dashboard";
import PartialResultsPage from "./components/pages/PartialResultsPage";

import { useHistory } from "react-router-dom";

import MyNavBar from "./components/basicComponents/MyNavbar";
import MyFooter from "./components/basicComponents/MyFooter";

const theme = createTheme({
  palette: {
    primary: {
      light: "#F3FAE8",
      main: "#88CC17",
    },
    secondary: {
      main: "#FA5A34",
    },
    grey: {
      lightGrey: "#F1F1F1",
      darkGrey: "#9E989D",
    },
    text: {
      primary: "#323234",
    },
  },
  typography: {
    fontFamily: "Rubik",
    h3: {
      fontSize: "2.25rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.125rem",
    },
    h6: {
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "0.875rem",
    },
    body2: {
      fontSize: "0.625rem",
    },
  },
});

function App({ location }) {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" || action === "REPLACE") {
        console.log("A redirection occurred:", { location, action });
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <MyNavBar />
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 250, exit: 250 }}
          classNames={"fade"}
        >
          <section className="route-section">
            <Switch location={location}>
              <PublicRoute exact path="/" component={WelcomePage} />
              <PublicRoute path="/login" component={LoginPage} />
              <Route path="/results/:userId" component={ResultsPage} />
              <PublicRoute path="/signup" component={SignupPage} />
              <PublicRoute
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <PublicRoute
                path="/reset-password"
                component={ResetPasswordPage}
              />
              <PrivateRoute path="/home" component={HomePage} />
              <Route
                path="/diagnostic/:theme/results"
                component={PartialResultsPage}
              />
              <Route
                path="/diagnostic/:theme"
                component={QuickDiagnosticPage}
              />
              <SimpleAuthRoute path="/diagnostic" component={FormPage} />
              <SimpleAuthRoute path="/results" component={ResultsPage} />
              <SimpleAuthRoute
                path="/edit_profile"
                component={EditProfilePage}
              />
              <AdminRoute
                path="/edit_questions"
                component={EditQuestionsPage}
              />
              <AdminRoute
                path="/edit_tutorials"
                component={EditTutorialsPage}
              />
              <AdminRoute path="/edit_products" component={EditProductsPage} />
              <AdminRoute path="/all_results" component={ResultsAnalysisPage} />
              <AdminRoute path="/dashboard" component={DashboardPage} />
              <Redirect from="*" to="/" />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
      <MyFooter />
    </ThemeProvider>
  );
}

export default withRouter(App);
