import api from "./api";

export default {
  getPaginateList: async function (page = 1, limit = 25) {
    return api
      .get(`/userresponses/list`, {
        params: { page, limit },
      })
      .then((res) => res.data.userResponses);
  },
  getUserResponse: async function (id) {
    return api.get(`/userresponses/${id}`).then((res) => res.data.userResponse);
  },
  getUserResponseByUser: async function (userId, shareHash = null) {
    return api
      .get(`/userresponses/user/${userId}`, {
        params: shareHash ? { shareHash } : {},
      })
      .then((res) => res.data.userResponse);
  },
};
