import axios from "axios";
import { burl } from "./config";
import api from "./api";

var headers = {
  "Content-Type": "application/json",
};

export default {
  signup: async function (userInfo) {
    return axios
      .post(`${burl}/user/signup`, userInfo, {
        headers: headers,
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: err.response.data.text };
          return errRes;
        },
      );
  },

  login: async function (userInfo) {
    return axios
      .post(`${burl}/user/login`, userInfo, {
        headers: headers,
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: err.response.data.text };
          return errRes;
        },
      );
  },

  logout: function () {
    localStorage.clear();

    localStorage.removeItem("token");
  },

  getUserInfo: async function (token) {
    return axios
      .get(`${burl}/user/getuser`, {
        params: { token: token },
        headers: headers,
      })
      .then((res) => res.data)
      .catch(() => null);
  },

  editProfile: async function (newUserInfo) {
    return axios
      .post(`${burl}/user/editprofile`, newUserInfo, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: err.response.data.text };
          return errRes;
        },
      );
  },
  deleteUser: async (userId) => {
    return api.delete(`/user/${userId}`);
  },
  disableUser: async (userId) => {
    return api.patch(`/user/${userId}`);
  },
  sendResetPasswordEmail: async (email) => {
    return axios.post(
      `${burl}/user/sendResetPasswordEmail`,
      { email },
      {
        headers: headers,
      },
    );
  },
  resetPassword: async ({ email, token, password }) => {
    return api.post("/user/resetPassword", { token, email, password });
  },
};
