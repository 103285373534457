import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

export default function Loader({ height = "100vh" }) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height }}
    >
      <CircularProgress
        disableShrink
        style={{ margin: "auto", color: "rgb(144, 208, 88)" }}
      />
    </Grid>
  );
}
