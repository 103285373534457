import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function HorsPerimetreIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88049 18.2807C10.2654 18.6423 10.8651 18.6423 11.25 18.2807L14.0377 15.6613L18.6699 15.6613C19.7745 15.6613 20.6699 14.7659 20.6699 13.6613L20.6699 1.99995C20.6699 0.895382 19.7745 -4.7652e-05 18.6699 -4.76129e-05L2.46056 -4.70392e-05C1.35599 -4.70002e-05 0.460558 0.895385 0.460556 1.99995L0.460559 13.6613C0.460559 14.7659 1.35599 15.6613 2.46056 15.6613L7.09275 15.6613L9.88049 18.2807Z"
        fill="#CCCCCC"
      />
      <path
        d="M10.9751 12.8648C13.7401 12.8648 15.9751 10.5262 15.9751 7.63302C15.9751 4.73984 13.7401 2.40123 10.9751 2.40123C8.2101 2.40123 5.9751 4.73984 5.9751 7.63302C5.9751 10.5262 8.2101 12.8648 10.9751 12.8648ZM8.4751 5.75481L9.1801 5.01713L10.9751 6.89534L12.7701 5.01713L13.4751 5.75481L11.6801 7.63302L13.4751 9.51123L12.7701 10.2489L10.9751 8.3707L9.1801 10.2489L8.4751 9.51123L10.2701 7.63302L8.4751 5.75481Z"
        fill="white"
      />
    </SvgIcon>
  );
}
