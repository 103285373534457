import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function APlanifierIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8382 18.3197C10.2233 18.6823 10.8242 18.6823 11.2093 18.3197L13.9963 15.6954L18.6284 15.6954C19.733 15.6954 20.6284 14.7999 20.6284 13.6954L20.6284 2C20.6284 0.895432 19.733 6.07959e-08 18.6284 1.35792e-07L2.41906 1.23634e-06C1.31449 1.31134e-06 0.419056 0.895431 0.419056 2L0.419057 13.6954C0.419057 14.7999 1.31449 15.6954 2.41906 15.6954L7.05116 15.6954L9.8382 18.3197Z"
      />
      <path
        d="M11.0005 7.53614L14.7505 3.78613"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.652 4.88457C12.9877 4.22161 12.0988 3.83212 11.1611 3.79315C10.2235 3.75418 9.30529 4.06858 8.5883 4.67414C7.87131 5.27971 7.40773 6.13232 7.28926 7.06331C7.17079 7.9943 7.40606 8.93585 7.94854 9.70168C8.49101 10.4675 9.30117 11.0018 10.2187 11.199C11.1363 11.3961 12.0944 11.2416 12.9036 10.7662C13.7127 10.2907 14.3139 9.52885 14.5883 8.63134C14.8626 7.73383 14.7901 6.76605 14.3851 5.91945"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3257 6.21027C12.0157 5.9003 11.6069 5.70874 11.1704 5.6689C10.7338 5.62905 10.2971 5.74344 9.9361 5.99218C9.57513 6.24092 9.31272 6.60828 9.19448 7.03041C9.07625 7.45255 9.10967 7.90277 9.28893 8.30282C9.46819 8.70288 9.78196 9.02747 10.1757 9.22019C10.5695 9.4129 11.0183 9.46156 11.4442 9.3577C11.8701 9.25383 12.2461 9.00402 12.5069 8.65168C12.7678 8.29933 12.8969 7.86673 12.8718 7.42907"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
