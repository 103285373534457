import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
} from "@material-ui/core";
import UserResponsesService from "../../../../../APIrequests/userResponseRequests";
import AccordionThemeUserResponse from "./AccordionThemeUserResponse";
import Loader from "../../../../common/Loader";
import { THEMES } from "../../../../../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function ModalUserResponse({ userResponse, isOpen, onClose }) {
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [completeUserResponse, setCompleteResponse] = useState(userResponse);

  useEffect(() => {
    if (isOpen) {
      setIsLoaded(false);
      UserResponsesService.getUserResponse(userResponse._id)
        .then(({ userResponse }) => {
          setCompleteResponse(userResponse);
        })
        .catch((erreur) => console.log(erreur))
        .finally(() => setIsLoaded(true));
    }
  }, [isOpen, userResponse]);

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Réponses{" "}
        {completeUserResponse.user?.company &&
          `de ${completeUserResponse.user.company}`}{" "}
        :
      </DialogTitle>

      {!isLoaded ? (
        <Loader height="400px" />
      ) : (
        <DialogContent>
          <div className={classes.root}>
            {THEMES.map((theme) => {
              const themeResponsesList = completeUserResponse.responses.filter(
                (questResPair) => {
                  return questResPair.question.theme === theme;
                }
              );
              return (
                <AccordionThemeUserResponse
                  key={theme}
                  theme={theme}
                  themeResponsesList={themeResponsesList}
                  scoresAndReco={completeUserResponse.scoresAndReco}
                />
              );
            })}
          </div>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => onClose()} style={{ color: "#90d058" }}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
