import React from "react";
import { Paper, Grid, Typography, List } from "@material-ui/core";
import ProductItem from "./ProductItem";

export default function ProductsList({
  products,
  updateProduct,
  deleteProduct,
  isWindowSizeBelowSm,
}) {
  return (
    <Grid item xs={10} sm={9} md={8} xl={6} style={{ margin: "auto" }}>
      <Paper
        elevation={5}
        style={{
          maxWidth: isWindowSizeBelowSm() ? "90vw" : "70vw",
          margin: "auto",
          marginTop: "30px",
          padding: "28px 28px 28px 28px",
        }}
      >
        <Typography component="h4" variant="h4">
          Solutions Numériques
        </Typography>
        <List>
          {products.map((product, index) => {
            return (
              <ProductItem
                key={index}
                product={product}
                updateProduct={updateProduct}
                deleteProduct={deleteProduct}
                divider={index < products.length - 1}
              />
            );
          })}
        </List>
      </Paper>
    </Grid>
  );
}
