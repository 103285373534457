import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Button } from "@material-ui/core";
import RessourcesService from "../../../APIrequests/ressourceRequests";
import ChipSelector from "../../common/forms/ChipSelector";
import MyModal from "../../common/MyModal";
import MyTextField from "../../common/forms/MyTextField";
import PhotoSelector from "../../common/forms/PhotoSelector";

const DEFAULT_PRODUCT = {
  productName: "",
  productLink: "",
  productDescription: "",
  tutorials: [],
  productPhoto: "",
  productPhotoFilename: "",
};

export default function ModalFormProduct({
  productToUpdate = DEFAULT_PRODUCT,
  isOpen,
  onClose,
  onSubmit,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [product, setProduct] = useState(productToUpdate);
  const [allTutorials, setAllTutorials] = useState([]);

  /**
   * A l'ouverture de la modal, récupération des solutions numériques et ressources
   */
  useEffect(() => {
    if (isOpen && productToUpdate) {
      RessourcesService.getAllRessources()
        .then(({ tutorials }) => {
          setAllTutorials(tutorials);
          setProduct({
            ...productToUpdate,
            tutorials: tutorials.filter((tutorial) =>
              productToUpdate.tutorials.includes(tutorial._id)
            ),
          });
          setIsLoaded(true);
        })
        .catch((erreur) => console.log(erreur));
    }
  }, [productToUpdate, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setProduct(DEFAULT_PRODUCT);
      setIsLoaded(false);
    }
  }, [isOpen]);

  const saveProduct = async () => {
    const productToSave = {
      ...product,
      tutorials: product.tutorials.map((tutorial) => tutorial._id),
    };
    await onSubmit(productToSave);
    onClose();
  };

  const updateAssociatedTutorial = (newList) => {
    setProduct({
      ...product,
      tutorials: newList,
    });
  };

  const ActionButtons = () => (
    <>
      <Button onClick={() => saveProduct()} style={{ color: "#90d058" }}>
        {product?._id ? "Modifier" : "Ajouter"}
      </Button>
      <Button onClick={() => onClose()} style={{ color: "#90d058" }}>
        Annuler
      </Button>
    </>
  );

  return (
    <MyModal
      title={product?._id ? "Modifier la solution numérique :" : "Ajouter une solution numérique :"}
      isOpen={isOpen}
      onClose={onClose}
      actions={<ActionButtons />}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        justifyContent="center"
        style={{ minHeight: "200px" }}
      >
        {!isLoaded ? (
          <CircularProgress
            disableShrink
            style={{ margin: "auto", color: "rgb(144, 208, 88)" }}
          />
        ) : (
          <>
            <MyTextField
              name="productName"
              label="Nom de la solution numérique"
              value={product.productName}
              onChange={(event) =>
                setProduct({
                  ...product,
                  productName: event.target.value,
                })
              }
            />
            <MyTextField
              name="productLink"
              label="Lien"
              value={product.productLink}
              onChange={(event) =>
                setProduct({
                  ...product,
                  productLink: event.target.value,
                })
              }
            />
            <MyTextField
              label="Description"
              onChange={(event) =>
                setProduct({
                  ...product,
                  productDescription: event.target.value,
                })
              }
              value={product.productDescription}
              name="productDescription"
              multiline
              minRows={4}
              maxRows={4}
            />
            <ChipSelector
              title="Ressources associés"
              type="ressource"
              actualList={product.tutorials}
              allList={allTutorials}
              updateList={updateAssociatedTutorial}
              labelKey="tutorialName"
            />
            <br />
            <PhotoSelector
              name={product.productPhotoFilename}
              photo={product.productPhoto}
              updatePhoto={(newName, newPhoto) =>
                setProduct({
                  ...product,
                  productPhotoFilename: newName,
                  productPhoto: newPhoto,
                })
              }
            />
          </>
        )}
      </Grid>
    </MyModal>
  );
}
