import React from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { useQuickForm } from "../contexts/QuickFormContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  button: {
    color: theme.palette.grey[50],
    transition: "scale 0.3s, color 0.3s, rotate 0.3s, background-color 0.3s",
    animation: "none",
    "&:hover": {
      color: theme.palette.grey[200],
      scale: 1.1,
    },
  },
  "@keyframes rotateButton": {
    "0%": {
      transform: "rotate(0deg)",
      scale: 1,
    },
    "10%": {
      transform: "rotate(3deg)",
      scale: 1.2,
    },
    "30%": {
      transform: "rotate(-3deg)",
    },
    "70%": {
      transform: "rotate(0deg)",
      scale: 1,
    },
  },
  animate: {
    animation: "$rotateButton 1.5s",
    animationDelay: ".3s",
  },
}));

export function MainFormCallToAction({ themeSlug }) {
  const formContext = useQuickForm();
  const { questions, answers } = formContext;

  const isCompleted = !questions.find((question) => !answers.has(question._id));

  const classes = useStyles();
  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justifyContent="center"
      style={{
        padding: "2rem",
      }}
    >
      <Button
        href={isCompleted ? `/diagnostic/${themeSlug}/results` : "#"}
        component="a"
        disabled={!isCompleted}
        className={`${classes.button} ${isCompleted ? classes.animate : ""}`}
        variant="contained"
        color="primary"
        size="large"
      >
        <Typography variant="h4">
          Découvrir votre score et les ressources liées
        </Typography>
      </Button>
    </Grid>
  );
}
