import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { KeyResult } from "./KeyResult";
import { NpsPieChart } from "./NpsPieChart";

function getCompletionRate(nbCompletedResponse, nbResponseCreated) {
  if (!nbResponseCreated) {
    return 0;
  }

  return parseFloat((nbCompletedResponse / nbResponseCreated) * 100).toFixed(2);
}

export default function KeyResults({ keyResults, isLoading }) {
  return (
    <div>
      {isLoading ? (
        <span>chargement</span>
      ) : (
        <Grid
          container
          spacing={5}
          justifyContent="space-evenly"
          style={{ marginTop: "24px" }}
        >
          <Grid item xs={6} md={4}>
            <KeyResult
              value={keyResults?.form?.nbResponseCreated}
              label="Questionnaires créés"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyResult
              value={
                keyResults
                  ? `${getCompletionRate(
                      keyResults.form.nbCompletedResponse,
                      keyResults.form.nbResponseCreated
                    )} %`
                  : ""
              }
              label="Taux de complétion des questionnaires"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyResult
              value={keyResults?.users?.nbUserCreated}
              label="Comptes créés"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyResult
              value={keyResults?.form.nbCompletedResponse}
              label="Questionnaires complétés au moins une fois"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyResult
              value={keyResults?.survey.totalSurvey}
              label="NPS répondus"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <KeyResult
              value={keyResults?.survey?.averageSurveyScore}
              label="Score moyen NPS"
            />
          </Grid>
          {keyResults?.survey?.scores?.length ? (
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold">
                Répartition des scores NPS
              </Typography>
              <div
                style={{
                  height: "300px",
                  width: "100%",
                }}
              >
                <NpsPieChart scores={keyResults?.survey?.scores || []} />
              </div>
            </Grid>
          ) : null}
        </Grid>
      )}
    </div>
  );
}
