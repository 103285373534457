import React, { useContext, useState, useEffect } from "react";
import { List, ListItem } from "@material-ui/core";
import { FormContext } from "../../contexts/FormContext";
import { QuestionAnswer } from "./QuestionAnswer";

export function MyTable() {
  const { questAndRes, themes, currentThemeNb } = useContext(FormContext);

  const [questionsToDisplay, setQuestionsToDisplay] = useState();

  useEffect(() => {
    function selectCurrentThemeQuestions(allQuestions) {
      var currentThemeQuestions = [];
      for (var key in allQuestions) {
        let question = allQuestions[key];
        //console.log(question);
        if (question.theme === themes[currentThemeNb]) {
          currentThemeQuestions.push(question);
        }
      }
      currentThemeQuestions = currentThemeQuestions.sort((quest1, quest2) => {
        return quest1.order - quest2.order;
      });
      return currentThemeQuestions;
    }

    const currentThemeQuestions = selectCurrentThemeQuestions(questAndRes);
    setQuestionsToDisplay(currentThemeQuestions);
  }, [currentThemeNb, questAndRes, themes]);

  if (!questionsToDisplay) {
    return null;
  } else {
    return (
      <List>
        {questionsToDisplay.map((question, index) => {
          return (
            <ListItem key={index}>
              <QuestionAnswer question={question} key={index} />
            </ListItem>
          );
        })}
      </List>
    );
  }
}
