import React from "react";
import { Box, Typography } from "@material-ui/core";

export default function StatusList({
  status,
  icon,
  toolList = [],
  maxColumns = 1,
}) {
  return (
    <Box>
      <Box display="flex" pb={1}>
        <Box pl={3}>{icon}</Box>
        <Box pl={1} pt={0.5}>
          <Typography>
            <Box fontWeight="fontWeightBold" component="span">
              {status}
            </Box>
          </Typography>
        </Box>
      </Box>

      <Typography component="div">
        {toolList.length ? (
          <ul style={{ marginTop: 0, columnCount: maxColumns }}>
            {toolList.map((tool, index) => (
              <li key={index}>{tool}</li>
            ))}
          </ul>
        ) : (
          <Box fontStyle="italic" pl={4}>
            Aucun outil
          </Box>
        )}
      </Typography>
    </Box>
  );
}
