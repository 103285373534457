import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import { DateTime } from "luxon";
import SearchIcon from "@material-ui/icons/Search";

export function TableUserRow({ user }) {
  const avgScore = user.response?.responses?.length
    ? (user.response?.responses?.reduce(
      (acc, { score }) => acc + (score || 0),
      0,
    ) /
      user.response?.responses?.length) *
    100
    : 0;
  const lastModified = user.response?.lastModified?.length
    ? user.response?.lastModified[0]
    : null;
  return (
    <TableRow key={user._id}>
      <TableCell scope="row">{user.company}</TableCell>
      <TableCell align="right">
        <Link href={`mailto:${user.email}`}>{user.email}</Link>
      </TableCell>
      <TableCell align="right">{user.sector}</TableCell>
      <TableCell align="right">
        {lastModified
          ? DateTime.fromISO(lastModified).toLocaleString(
            DateTime.DATETIME_SHORT,
          )
          : ""}
      </TableCell>
      <TableCell align="right">
        {DateTime.fromISO(user.createdAt).toLocaleString(
          DateTime.DATETIME_SHORT,
        )}
      </TableCell>
      <TableCell align="right">{user.response?.nbMissingAnswers}</TableCell>
      <TableCell align="right">{avgScore.toFixed(2)} %</TableCell>
      <TableCell align="right">
        {user.response?.nbMissingAnswers === 0 ? (
          <Link href={`/results/${user._id}`}>
            <IconButton size="small" edge="end">
              <SearchIcon />
            </IconButton>
          </Link>
        ) : (
          <IconButton size="small" edge="end" disabled>
            <SearchIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
