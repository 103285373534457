import React from "react";
import { Avatar, Box, makeStyles, useMediaQuery } from "@material-ui/core";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CarouselCard from "./CarouselCard";
import CarouselLastCard from "./CarouselLastCard";
import "./Carousel.css";

const useStyles = makeStyles((theme) => ({
  arrowRoot: {
    width: "40px",
    height: "40px",
    transform: "translate(0, -80%)",
    zIndex: 1,
    display: ({ className }) => {
      return className.includes("slick-disabled") ? "none" : "block";
    },
  },
  arrowAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  arrowIcon: {
    color: theme.palette.common.white,
    margin: "auto",
  },
  rotated: {
    transform: "rotate(180deg)",
  },
}));

function PreviousButton({ className, onClick }) {
  const classes = useStyles({ className });
  return (
    <div className={`${className} ${classes.arrowRoot}`} onClick={onClick}>
      <Avatar className={classes.arrowAvatar}>
        <ArrowForwardIosIcon className={(classes.arrowIcon, classes.rotated)} />
      </Avatar>
    </div>
  );
}
function NextButton({ className, onClick }) {
  const classes = useStyles({ className });
  return (
    <div className={`${className} ${classes.arrowRoot}`} onClick={onClick}>
      <Avatar className={classes.arrowAvatar}>
        <ArrowForwardIosIcon className={classes.arrowIcon} />
      </Avatar>
    </div>
  );
}

export default function Carousel({ responses }) {
  const isDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isDownMD = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isDownLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  let slidesToShow = 5;

  if (isDownSM) {
    slidesToShow = 2;
  } else if (isDownMD) {
    slidesToShow = 3;
  } else if (isDownLG) {
    slidesToShow = 4;
  }

  const settings = {
    infinite: false,
    nextArrow: <NextButton />,
    prevArrow: <PreviousButton />,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  const elements = responses
    .map((response) => {
      const products = response.question.products.map((product) => {
        product.toolType = response.question.toolType;
        product.type = "product";
        return product;
      });
      const tutorials = response.question.tutorials.map((tutorial) => {
        tutorial.toolType = response.question.toolType;
        tutorial.type = "tutorial";
        return tutorial;
      });
      return [...products, ...tutorials];
    })
    .flat();

  return (
    <Box p={2}>
      <Slider {...settings}>
        {elements.map((element, i) => (
          <CarouselCard key={i} element={element} type={element.type} />
        ))}
        <CarouselLastCard />
      </Slider>
    </Box>
  );
}
