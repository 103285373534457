import React from "react";
import { Link } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import Mail from "@material-ui/icons/Mail";
import FacebookIcon from "@material-ui/icons/Facebook";
import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  link: {
    marginLeft: '2rem',
    color: theme.palette.grey[500],
    transition: 'color 0.3s',
  },
  facebook: {
    "&:hover": {
      color: "#4267B2",
    }
  },
  twitter: {
    "&:hover": {
      color: "#1DA1F2",
    }
  },
  mail: {
    "&:hover": {
      color: "#D44638",
    }
  }
}));

export default function SocialMediaList() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link
        className={`${classes.link} ${classes.facebook}`}
        href="https://www.facebook.com/Solidatech/"
        label="Facebook"
      >
        <FacebookIcon />
      </Link>
      <Link
        className={`${classes.link} ${classes.twitter}`}
        href="https://twitter.com/solidatech"
        label="Twitter"
      >
        <TwitterIcon />
      </Link>
      <Link
        className={`${classes.link} ${classes.mail}`}
        href="mailto:contact@solidatech.fr"
        label="Contact"
      >
        <Mail />
      </Link>
    </div >
  );
}

