import React, { useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, Typography, Button } from "@material-ui/core";
import { THEMES_CONFIG } from "../../../constants";
import { PillarDetails } from "../../results/PillarDetails";
import CircularPercentage from "../../results/CircularPercentage";
import { PartialResultsCTA } from "../../results/PartialResultsCTA";
import { QuickFormProvider } from "../../contexts/QuickFormContext";
import { useQuickForm } from "../../contexts/QuickFormContext";
import { UserContext } from "../../contexts/UserContext";
import { PageContentWrapper } from "../../basicComponents/PageContentWrapper";
import { PaperWrapper } from "../../basicComponents/PaperWrapper";
import { getAnswerScore } from "../../../services/getAnswerScore";

function Content({ theme, description }) {
  let history = useHistory();
  const { user } = useContext(UserContext);
  const { questions, answers } = useQuickForm();
  const formatedAnswers = questions.map((question) => {
    const answer = answers.get(question._id);
    return {
      question: question,
      response: answer ? answer : 0,
    };
  });
  if (answers.size < questions.length) {
    setTimeout(
      () => history.push(window.location.pathname.replace("/results", "")),
      0,
    );
    return null;
  }
  const score =
    (formatedAnswers
      .map(({ response }) => getAnswerScore(response))
      .reduce((a, b) => a + b, 0) /
      formatedAnswers.length) *
    100;

  return (
    <PageContentWrapper>
      <QuickFormProvider theme={theme}>
        <PaperWrapper>
          <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="center"
            alignItems="end"
            textAlign="center"
            mb={8}
          >
            <Typography variant="h3" style={{ margin: "1rem" }}>
              Votre score sur le pilier <br /> "{theme}" est de :{" "}
            </Typography>
            <CircularPercentage
              percentage={score}
              style={{ minWidth: "70px" }}
              size={70}
            />
          </Box>
          <Box mt={2} />
          <p>{description}</p>
          <Box mt={6} />
          <PillarDetails responses={formatedAnswers} />
        </PaperWrapper>
        {user ? (
          <>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                component="a"
                href="/results"
                variant="contained"
                color="primary"
                size="large"
              >
                <Typography variant="subtitle1">
                  Voire les resultats de tous vos piliers
                </Typography>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box mt={2} />
            <PaperWrapper>
              <PartialResultsCTA />
            </PaperWrapper>
          </>
        )}
      </QuickFormProvider>
    </PageContentWrapper>
  );
}

export default function PartialResultsPage() {
  const params = useParams();
  let history = useHistory();

  const theme = THEMES_CONFIG.find(
    (theme) => theme.slug.toLowerCase() === params.theme.toLowerCase(),
  );
  if (!theme) {
    setTimeout(() => history.push("/"), 0);
    return null;
  }

  return (
    <QuickFormProvider theme={theme.title}>
      <Content theme={theme.title} description={theme.description} />
    </QuickFormProvider>
  );
}
