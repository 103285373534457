import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { MainFormQuestionStepper } from "./MainFormQuestionStepper";
import { useQuickFormDispatch } from "../contexts/QuickFormContext";

export function MainFormQuestionItem(props) {
  const dispatch = useQuickFormDispatch();

  const onAnswerUpdate = (response) => {
    dispatch({
      type: "update",
      payload: { question: props.question, response },
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Grid item xs={12} xl={4}>
        <Typography variant="h5">{props.question.questionText}</Typography>
      </Grid>
      <Grid item xs={12} xl={8}>
        <MainFormQuestionStepper
          onUpdate={onAnswerUpdate}
          question={props.question}
          answer={props.answer}
        />
      </Grid>
    </Grid>
  );
}
