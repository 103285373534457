import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Box, Container, Grid, Typography, useTheme } from "@material-ui/core";
import AccordionItem from "../../results/AccordionItem";
import UserResultService from "../../../APIrequests/userResponseRequests";
import ResultRadar from "../../results/ResultRadar";
import Survey from "../../results/Survey";
import { UserContext } from "../../contexts/UserContext";
import Loader from "../../common/Loader";
import SectionActions from "../../results/SectionActions";
import SponsorList from "../../basicComponents/SponsorList";

export default function ResultsPage() {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const userContext = useContext(UserContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [userResult, setUserResult] = useState();
  const [shareHash, setShareHash] = useState(
    new URLSearchParams(location.search).get("token")
  );

  useEffect(() => {
    setIsLoaded(false);
    setShareHash(new URLSearchParams(location.search).get("token"));
    const userId = params.userId || userContext.user.id;
    UserResultService.getUserResponseByUser(userId, shareHash)
      .then(({ userResponse }) => {
        setUserResult(userResponse);
      })
      .catch((erreur) => console.log(erreur))
      .finally(() => setIsLoaded(true));
  }, [userContext, params, location.search, shareHash]);

  return (
    <Container
      style={{
        backgroundColor: theme.palette.grey.lightGrey,
        minHeight: "100vh",
        paddingBottom: "5rem",
      }}
      maxWidth={false}
    >
      {!isLoaded ? (
        <Loader />
      ) : !userResult ? (
        <Redirect to="/diagnostic" />
      ) : (
        <Box p={8} pt={12}>
          <Box
            mt={1}
            p={2}
            mb={1}
            style={{
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h3" component="h4" color="textPrimary">
              {shareHash
                ? `Résultats de ${userResult.user.company}`
                : "Vos résultats"}
            </Typography>
            <Grid container>
              <Grid
                item
                xs={12}
                lg={6}
                style={{
                  alignSelf: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="p"
                  color="textPrimary"
                >
                  Merci pour vos réponses !
                  <br />
                  <br />
                  Pour chaque pilier, découvrez de nombreux logiciels et
                  solutions en ligne, gratuits ou à tarifs solidaires, ainsi que
                  des ressources à explorer (tutoriels, webinaires,
                  conseils...). Ils sont directement liés aux sujets "à
                  planifier" au sein de votre association.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{
                  height: "400px",
                  alignSelf: "center",
                }}
                id="response-radar"
              >
                <ResultRadar data={userResult.scoresAndReco.userScores} />
              </Grid>
            </Grid>
          </Box>
          <AccordionItem
            theme="Animez votre réseau"
            description="L'animation consiste à établir un lien avec votre réseau d'adhérents et de bénévoles, mais également à le faire interagir pour gagner en implication et participation des membres."
            responses={userResult.responses.filter(
              (response) => response.question.theme === "Animez votre réseau"
            )}
            score={userResult.scoresAndReco?.userScores["Animez votre réseau"]}
            defaultExpanded
          />
          <AccordionItem
            theme="Gagnez en visibilité sur le web"
            description="Être visible sur le web est un vrai enjeu : sensibiliser aux causes de votre association, développer votre notoriété, renforcer vos actions ou trouver de nouvelles ressources (financières, bénévoles, matérielles...)."
            responses={userResult.responses.filter(
              (response) =>
                response.question.theme === "Gagnez en visibilité sur le web"
            )}
            score={
              userResult.scoresAndReco?.userScores[
              "Gagnez en visibilité sur le web"
              ]
            }
          />
          <AccordionItem
            theme="Travaillez collaborativement"
            description="Le travail collaboratif consiste à fluidifier le partage d'information et libérer la créativité en équipe. Cela commence par donner à vos membres un accès à un espace de travail partagé pour lire, stocker des fichiers puis à travailler à plusieurs et en simultané sur ces supports numériques."
            responses={userResult.responses.filter(
              (response) =>
                response.question.theme === "Travaillez collaborativement"
            )}
            score={
              userResult.scoresAndReco?.userScores[
              "Travaillez collaborativement"
              ]
            }
          />
          <AccordionItem
            theme="Financez vos projets"
            description="Pour fonctionner et se développer, les associations ont toutes besoin de financement. Le numérique offre de nombreuses opportunités pour optimiser vos recherches de fonds, mobiliser de nouveaux financeurs ou développer de nouvelles sources de revenus."
            responses={userResult.responses.filter(
              (response) => response.question.theme === "Financez vos projets"
            )}
            score={userResult.scoresAndReco?.userScores["Financez vos projets"]}
          />
          <AccordionItem
            theme="Gérez vos ressources humaines et financières"
            description="Que ce soit pour accompagner, gérer ou former ses équipes, qu'elles soient salariées ou bénévoles, ou encore pour faire des projections financières, certains outils peuvent vous aider à être plus efficace."
            responses={userResult.responses.filter(
              (response) =>
                response.question.theme ===
                "Gérez vos ressources humaines et financières"
            )}
            score={
              userResult.scoresAndReco?.userScores[
              "Gérez vos ressources humaines et financières"
              ]
            }
          />
          <AccordionItem
            theme="Sécurisez votre association et suivez les réglementations"
            description="Les risques existent liés à l'utilisation abusive des données personnelles et les cyberattaques existent ! Pour les limiter, les associations sont soumises à des règles. Un ensemble de bonnes pratiques vous aideront à renforcer la sécurité et la protection de vos données."
            responses={userResult.responses.filter(
              (response) =>
                response.question.theme ===
                "Sécurisez votre association et suivez les réglementations"
            )}
            score={
              userResult.scoresAndReco?.userScores[
              "Sécurisez votre association et suivez les réglementations"
              ]
            }
          />
          <AccordionItem
            theme="Pilotez la stratégie de votre transformation numérique"
            description="La mise en place d'outils numériques amène de nouvelles façons de s'organiser et de travailler. Pour que les outils choisis répondent bien aux objectifs recherchés par votre association, il est fondamental de susciter l'adhésion des futurs utilisateurs et de suivre les changements."
            responses={userResult.responses.filter(
              (response) =>
                response.question.theme ===
                "Pilotez la stratégie de votre transformation numérique"
            )}
            score={
              userResult.scoresAndReco?.userScores[
              "Pilotez la stratégie de votre transformation numérique"
              ]
            }
          />
          <SectionActions />
          {!shareHash && <Survey />}
          <SponsorList style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,

          }} />
        </Box>
      )}
    </Container>
  );
}
