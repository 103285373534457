import React from "react";
import { Grid, Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(8),
  },
}));

export function PaperWrapper({ children, ...props }) {
  const classes = useStyles();

  return (
    <Box>
      <Grid item component={Paper} className={classes.paper} {...props}>
        {children}
      </Grid>
    </Box>
  );
}
