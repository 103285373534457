import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";

import formRequests from "../../APIrequests/formRequests";

import { UserContext } from "./UserContext";

export const FormContext = createContext();

const themes = [
  "Animez votre réseau",
  "Gagnez en visibilité sur le web",
  "Travaillez collaborativement",
  "Financez vos projets",
  "Gérez vos ressources humaines et financières",
  "Sécurisez votre association et suivez les réglementations",
  "Pilotez la stratégie de votre transformation numérique",
];

export function FormContextWrapper({ children }) {
  const { user } = useContext(UserContext);
  const [questAndRes, setQuestAndRes] = useState(null);
  const [currentThemeNb, setCurrentThemeNb] = useState(0);
  const [alreadyModified, setAlreadyModified] = useState(false);
  const [unanswered, setUnanswered] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const questionThemesNb = 6;

  const persistQuestAndResToLocalStorage = useCallback(() => {
    if (questAndRes === null) {
      return;
    }
    localStorage.setItem("questAndRes", JSON.stringify(questAndRes));
  }, [questAndRes]);

  // à lancer dans le component did mount de la page de questionnaire
  const retrieveQuestions = useCallback(async () => {
    if ((alreadyModified, isLoaded)) {
      return;
    }
    const { data } = await formRequests.getUserResponses(user?.id);
    const prevRes = data;
    var resOtherFormat = {};
    let nullCount = 0;
    for (var i in prevRes) {
      const questResPair = prevRes[i];
      if (questResPair.response === null) {
        nullCount += 1;
      }
      resOtherFormat[questResPair.question._id] = {
        questResPairId: questResPair._id,
        response: questResPair.response,
        ...questResPair.question,
      };
    }
    if (nullCount === prevRes.length) {
      setAlreadyModified(false);
    } else {
      setAlreadyModified(true);
    }
    setIsLoaded(true);
    setQuestAndRes(resOtherFormat);
    persistQuestAndResToLocalStorage();
  }, [alreadyModified, user, persistQuestAndResToLocalStorage, isLoaded]);

  async function sendResults() {
    var computedResults = null;
    const questAndResDBFormat = [];
    for (let key in questAndRes) {
      const questResPair = questAndRes[key];
      questAndResDBFormat.push({
        _id: questResPair.questResPairId,
        question: {
          //on reconstitue la question pour éviter d'avoir à repopuler une fois reçu dans le back
          _id: questResPair._id,
          questionText: questResPair.questionText,
          theme: questResPair.theme,
          order: questResPair.order,
          products: questResPair.products,
          tutorials: questResPair.tutorials,
          createdAt: questResPair.createdAt,
        },
        response: questResPair.response,
      });
    }
    //console.log(questAndResDBFormat)
    computedResults = await formRequests.updateUserResAndComputeResults(
      user.id,
      questAndResDBFormat,
      themes,
    );
    return computedResults;
  }

  async function onDisconnect() {
    //console.log(questAndRes);
    if (questAndRes !== null) {
      //console.log('questAndRes bien non null')
      const questAndResDBFormat = [];
      for (let key in questAndRes) {
        const questResPair = questAndRes[key];
        questAndResDBFormat.push({
          _id: questResPair.questResPairId,
          question: questResPair._id,
          response: questResPair.response,
        });
      }
      //console.log("changement de format OK")
      //console.log(questAndResDBFormat)
      await formRequests.updateUserResponses(user.id, questAndResDBFormat);
      //console.log("résultats envoyés")
    }
    setQuestAndRes(null);
    setCurrentThemeNb(0);
    setAlreadyModified(false);
  }

  useEffect(() => {
    const onStorage = (e) => {
      if (e.key === "questAndRes") {
        const storedQuestAndRes = JSON.parse(e.newValue);
        setQuestAndRes(storedQuestAndRes);
      }
    };
    window.addEventListener("storage", onStorage);
    return () => window.removeEventListener("storage", onStorage);
  });

  async function modifyRes(questionId, responseGiven) {
    if (!alreadyModified) {
      setAlreadyModified(true);
    }
    setQuestAndRes((currentQuestAndRes) => {
      currentQuestAndRes[questionId].response = responseGiven;
      return currentQuestAndRes;
    });

    const questAndResDBFormat = [];
    for (let key in questAndRes) {
      const questResPair = questAndRes[key];
      questAndResDBFormat.push({
        _id: questResPair.questResPairId,
        question: questResPair._id,
        response: questResPair.response,
      });
    }
    await formRequests.updateUserResponses(user.id, questAndResDBFormat);
    persistQuestAndResToLocalStorage();
  }

  function incrementThemeNb() {
    setCurrentThemeNb((currentNb) => currentNb + 1);
  }

  function decrementThemeNb() {
    setCurrentThemeNb((currentNb) => currentNb - 1);
  }

  return (
    <FormContext.Provider
      value={{
        retrieveQuestions,
        modifyRes,
        questAndRes,
        setQuestAndRes,
        currentThemeNb,
        themes,
        incrementThemeNb,
        decrementThemeNb,
        setCurrentThemeNb,
        questionThemesNb,
        /*checkIfFinished,
                finished,*/
        alreadyModified,
        onDisconnect,
        unanswered,
        setUnanswered,
        sendResults,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
