import React from "react";
import { Grid, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Carousel from "./Carousel";
import StatusList from "./StatusList";
import APlanifierIcon from "./StatusList/SVG/APlanifierIcon";
import EnCoursIcon from "./StatusList/SVG/EnCoursIcon";
import ExistantIcon from "./StatusList/SVG/ExistantIcon";
import HorsPerimetreIcon from "./StatusList/SVG/HorsPerimetreIcon";
import ARevoirIcon from "./StatusList/SVG/ARevoirIcon";

export function PillarDetails({ responses }) {
  const solidatechTheme = useTheme();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <StatusList
          status="À PLANIFIER"
          icon={<APlanifierIcon color="primary" viewBox="0 0 21 19" />}
          toolList={Array.from(
            responses.filter((response) => response.response === 2),
            (response) => response.question.toolType,
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatusList
          status="À REVOIR"
          icon={<ARevoirIcon viewBox="0 0 21 19" />}
          toolList={Array.from(
            responses.filter((response) => response.response === 5),
            (response) => response.question.toolType,
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatusList
          status="C'EST EN COURS"
          icon={<EnCoursIcon viewBox="0 0 21 19" />}
          toolList={Array.from(
            responses.filter((response) => response.response === 3),
            (response) => response.question.toolType,
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatusList
          status="EXISTANT"
          icon={<ExistantIcon viewBox="0 0 21 19" />}
          toolList={Array.from(
            responses.filter((response) => response.response === 4),
            (response) => response.question.toolType,
          )}
        />
      </Grid>
      {responses.filter((response) => response.response === 2).length ? (
        <>
          <Grid item xs={12}>
            <Container
              style={{
                backgroundColor: solidatechTheme.palette.primary.light,
              }}
              maxWidth={false}
              disableGutters
            >
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={4}
                  style={{
                    borderTop: `3px solid ${solidatechTheme.palette.primary.main}`,
                  }}
                ></Grid>
              </Grid>
              <Carousel
                responses={responses.filter(
                  (response) => response.response === 2,
                )}
              />
            </Container>
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <StatusList
          status="HORS PERIMETRE"
          icon={<HorsPerimetreIcon viewBox="0 0 21 19" />}
          maxColumns={4}
          toolList={Array.from(
            responses.filter((response) => response.response <= 1),
            (response) => response.question.toolType,
          )}
        />
      </Grid>
    </Grid>
  );
}
