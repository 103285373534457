import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function ARevoirIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41764 18.3894C9.80312 18.7538 10.4061 18.7538 10.7915 18.3894L13.577 15.7564L18.2092 15.7564C19.3138 15.7564 20.2092 14.861 20.2092 13.7564L20.2092 2C20.2092 0.895426 19.3138 -3.87549e-06 18.2092 -3.95049e-06L1.99988 -5.05104e-06C0.895312 -5.12603e-06 -0.000118163 0.895426 -0.000118287 1.99999L-0.000119611 13.7564C-0.000119735 14.861 0.89531 15.7564 1.99988 15.7564L6.63219 15.7564L9.41764 18.3894Z"
        fill="#9E989D"
      />
      <path
        fill="white"
        style={{ transform: "scale(0.65) translate(4px, 1px)" }}
        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      ></path>
    </SvgIcon>
  );
}
