import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: "#90d058",
  },
}));

export default function MyBackdrop(props) {
  /* Style */
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} {...props}>
      <CircularProgress disableShrink color="inherit" />
    </Backdrop>
  );
}
