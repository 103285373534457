import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DeleteButton({
  deleteElement,
  elementTitre = "",
  iconColor = "",
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const closeDialog = (confirmDelete = false) => {
    setOpenDialog(false);
    if (confirmDelete) {
      deleteElement();
    }
  };

  return (
    <>
      <IconButton size="small" edge="end" onClick={() => setOpenDialog(true)}>
        <DeleteIcon style={{ color: iconColor }} />
      </IconButton>

      <Dialog
        open={openDialog}
        onClose={() => closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Suppression d'un élément</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Voulez-vous vraiment supprimer cet élément ?</p>
            {elementTitre && (
              <ul>
                <li>{elementTitre}</li>
              </ul>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeDialog(true)}
            style={{ color: "#dd1923" }}
            autoFocus
          >
            Supprimer
          </Button>
          <Button onClick={() => closeDialog()} style={{ color: "#90d058" }}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
