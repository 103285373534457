import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import TutorialsService from "../../../APIrequests/tutorialRequests";
import Loader from "../../common/Loader";
import TutorialsList from "../../tutorials/TutorialsList";
import MyBackdrop from "../../common/MyBackdrop";
import MySnackbar from "../../common/MySnackbar";
import MyFloatingButton from "../../common/MyFloatingButton";
import ModalFormTutorial from "../../tutorials/ModalFormTutorial";

export default function EditTutorialsPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [tutorials, setTutorials] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tutorialToUpdate, setTutorialToUpdate] = useState(null);

  const { width } = useWindowDimensions();

  /**
   * Récupération des solutions numériques pour la page
   */
  useEffect(() => {
    TutorialsService.getTutorials()
      .then((tutorials) => setTutorials(tutorials))
      .catch(() => {
        setSnackbarMessage("Une erreur est survenue.");
        setSnackbarOpen(true);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  /** Gestion de la Modal */

  const openModalFormTutorial = (tutorial) => {
    setTutorialToUpdate(tutorial);
    setModalIsOpen(true);
  };

  const closeModalFormTutorial = () => {
    setTutorialToUpdate(null);
    setModalIsOpen(false);
  };

  /**
   * Supprime la solution numérique passé en paramètre de la base de données puis en local.
   * @param {*} tutorialToDelete
   */
  const deleteTutorial = async (tutorialToDelete) => {
    setBackdropOpen(true);

    try {
      const result = await TutorialsService.deleteTutorial(tutorialToDelete);

      if (result.data.text !== "Erreur serveur") {
        setTutorials([
          ...tutorials.filter(
            (tutorial) => tutorial._id !== tutorialToDelete._id
          ),
        ]);
      }
      setSnackbarMessage(result.data.text);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue.");
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  /**
   * Ajoute la solution numérique reçu s'il n'existe pas ou l'édite de la base de données.
   * @param {*} tutorial A ajouter ou éditer
   */
  const addOrEditTutorial = async (tutorial) => {
    setBackdropOpen(true);
    try {
      let result;
      if (tutorial._id) {
        result = await TutorialsService.updateTutorial(tutorial);
      } else {
        result = await TutorialsService.createTutorial(tutorial);
      }
      setSnackbarMessage(result.data.text);
      setTutorialToUpdate(null);
      const updatedTutorials = await TutorialsService.getTutorials();
      setTutorials(updatedTutorials);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue");
      return erreur;
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  if (!isLoaded) {
    return <Loader />;
  } else {
    return (
      <div
        style={{
          marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
          marginBottom: isWindowSizeBelowSm()
            ? "110px"
            : "180px" /*overflowY: "scroll"*/,
        }}
      >
        <MyBackdrop open={backdropOpen} />
        <MySnackbar
          message={snackbarMessage}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
        <TutorialsList
          tutorials={tutorials}
          deleteTutorial={deleteTutorial}
          updateTutorial={openModalFormTutorial}
          isWindowSizeBelowSm={isWindowSizeBelowSm}
        />
        <MyFloatingButton onClick={openModalFormTutorial} />
        <ModalFormTutorial
          isOpen={modalIsOpen}
          onClose={closeModalFormTutorial}
          onSubmit={addOrEditTutorial}
          tutorialToUpdate={tutorialToUpdate}
        />
      </div>
    );
  }
}
