import React from "react";
import {
  ListItem,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteButton from "../../../common/forms/DeleteButton";

export default function ProductItem({
  product,
  updateProduct,
  deleteProduct,
  divider,
}) {
  return (
    <>
      <ListItem>
        <ListItemText
          primary={<Typography variant="h5">{product.productName}</Typography>}
          secondary={
            <Box color="grey.darkGrey">
              <Typography variant="body1">
                {product.productDescription !== "" && (
                  <>
                    <i>{product.productDescription}</i>
                    <br />
                  </>
                )}
                <a
                  href={product.productLink}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {product.productLink}
                </a>
              </Typography>
            </Box>
          }
          style={{ marginRight: "30px" }}
        />
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            edge="end"
            onClick={() => updateProduct(product)}
          >
            <EditIcon />
          </IconButton>
          <DeleteButton
            deleteElement={() => deleteProduct(product)}
            elementTitre={product.productName}
          />
        </ListItemSecondaryAction>
      </ListItem>

      {divider && <Divider component="li" />}
    </>
  );
}
