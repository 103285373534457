import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";

export default function CarouselLastCard() {
  return (
    <Card
      elevation={0}
      style={{
        margin: "0 8px",
        height: "100%",
        /* display: "flex",
        flexDirection: "column", */
      }}
    >
      <CardActionArea
        href="https://www.solidatech.fr/utiliser/centre-de-ressources"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          height: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CardContent>
            <Typography variant="h5">Voir plus de Ressources</Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
}
