import React from "react";
import { makeStyles, Grid, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "88px",
      marginRight: "88px",
    },
  },
}));

export function MainFormHeader({ children }) {
  const classes = useStyles();

  const tableSizes = {
    xs: 12,
  };
  return (
    <Grid
      component={Paper}
      item
      {...tableSizes}
      className={classes.margin}
      style={{
        backgroundColor: "#90d058",
        color: "white",
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        textAlign: "center",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" fontWeight="bold">
        {children}
      </Typography>
    </Grid>
  );
}
