import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function SolutionNumeriqueIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect width="14" height="14" rx="2" fill="#FFC662" fillOpacity="0.2" />
      <path
        opacity="0.5"
        d="M7.645 7.3479L7.5 7.49V6.26316C7.5 6.13753 7.44732 6.01705 7.35355 5.92822C7.25979 5.83939 7.13261 5.78948 7 5.78948C6.86739 5.78948 6.74021 5.83939 6.64645 5.92822C6.55268 6.01705 6.5 6.13753 6.5 6.26316V7.49L6.355 7.3479C6.26085 7.2587 6.13315 7.20859 6 7.20859C5.86685 7.20859 5.73915 7.2587 5.645 7.3479C5.55085 7.43709 5.49795 7.55807 5.49795 7.68421C5.49795 7.81036 5.55085 7.93133 5.645 8.02053L6.645 8.9679C6.69255 9.01102 6.74862 9.04483 6.81 9.06737C6.86985 9.09243 6.93456 9.10537 7 9.10537C7.06544 9.10537 7.13015 9.09243 7.19 9.06737C7.25138 9.04483 7.30745 9.01102 7.355 8.9679L8.355 8.02053C8.44915 7.93133 8.50205 7.81036 8.50205 7.68421C8.50205 7.55807 8.44915 7.43709 8.355 7.3479C8.26085 7.2587 8.13315 7.20859 8 7.20859C7.86685 7.20859 7.73915 7.2587 7.645 7.3479ZM10.5 3.42106H7.36L7.2 2.94738C7.09628 2.66943 6.90387 2.42893 6.64945 2.25919C6.39503 2.08945 6.09118 1.99887 5.78 2.00001H3.5C3.10218 2.00001 2.72064 2.14973 2.43934 2.41623C2.15804 2.68273 2 3.04418 2 3.42106V9.57895C2 9.95583 2.15804 10.3173 2.43934 10.5838C2.72064 10.8503 3.10218 11 3.5 11H10.5C10.8978 11 11.2794 10.8503 11.5607 10.5838C11.842 10.3173 12 9.95583 12 9.57895V4.84211C12 4.46523 11.842 4.10378 11.5607 3.83728C11.2794 3.57078 10.8978 3.42106 10.5 3.42106ZM11 9.57895C11 9.70458 10.9473 9.82506 10.8536 9.91389C10.7598 10.0027 10.6326 10.0526 10.5 10.0526H3.5C3.36739 10.0526 3.24021 10.0027 3.14645 9.91389C3.05268 9.82506 3 9.70458 3 9.57895V3.42106C3 3.29543 3.05268 3.17495 3.14645 3.08612C3.24021 2.99728 3.36739 2.94738 3.5 2.94738H5.78C5.88482 2.94712 5.98708 2.97808 6.07231 3.03588C6.15755 3.09368 6.22146 3.1754 6.255 3.26948L6.525 4.04632C6.55854 4.14041 6.62245 4.22212 6.70769 4.27992C6.79292 4.33773 6.89518 4.36869 7 4.36843H10.5C10.6326 4.36843 10.7598 4.41833 10.8536 4.50717C10.9473 4.596 11 4.71648 11 4.84211V9.57895Z"
        fill="#9E989D"
      />
    </SvgIcon>
  );
}
