import React from "react";
import { makeStyles } from "@material-ui/core";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  margin: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "88px",
      marginRight: "88px",
    },
  },
}));

export function MainFormWrapper({ children }) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={("SolidaFormPage", classes.root)}
      style={{
        marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
        marginBottom: isWindowSizeBelowSm() ? "110px" : "190px",
        overflowY: "scroll",
      }}
    >
      {children}
    </div>
  );
}
