import React, { useState, useContext } from "react";
import {
  Grid,
  Avatar,
  Button,
  Card,
  CardContent,
  makeStyles,
  Snackbar,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MailIcon from "@material-ui/icons/Mail";
import BusinessIcon from "@material-ui/icons/Business";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import SaveIcon from "@material-ui/icons/Save";
import DeleteForever from "@material-ui/icons/DeleteForever";
import CloseIcon from "@material-ui/icons/Close";
import MyTextFieldSignUp from "../../basicComponents/MyTextFieldSignUp";
import userRequests from "../../../APIrequests/userRequests";
import MyBackdrop from "../../common/MyBackdrop";
//import useWindowDimensions from "../../../customHooks/useWindowDimensions"

import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cssLabel: {
    color: "#90d058 !important",
  },
  floatingLabelFocusStyle: {
    color: "#90d058 !important",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#90d058 !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
  },
}));

export function EditProfileForm(props) {
  const classes = useStyles();

  //const { height } = useWindowDimensions();

  const [backdropOpen, setBackdropOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { user, setUser, sectors } = useContext(UserContext);

  var [email, setEmail] = useState(user.email);
  var [password, setPassword] = useState("");
  var [cpassword, setCpassword] = useState("");
  var [company, setCompany] = useState(user.company);
  var [sector, setSector] = useState("");
  //seul moyen de régler le pb de floatting label qui se superpose au texte input avec material

  var [emailError, setEmailError] = useState(false);
  var [emailWrongFormat, setEmailWrongFormat] = useState(false);
  var [emailAlreadyTaken, setEmailAlreadyTaken] = useState(false);
  var [companyAlreadyFiled, setCompanyAlreadyFiled] = useState(false);
  var [passwordError, setPasswordError] = useState(false);
  var [cpasswordError, setCpasswordError] = useState(false);
  var [companyError, setCompanyError] = useState(false);
  var [sectorError, setSectorError] = useState(false);
  //pour gérer les erreurs

  function validateEmail(emailString) {
    const re = /\S+@\S+\.\S+/;
    //const re = new RegExp("^[^\s@]+@[^\s@]+\.[^\s@]+$");
    return re.test(emailString);
  }

  async function editProfile() {
    setBackdropOpen(true);

    setEmailAlreadyTaken(false);
    setCompanyAlreadyFiled(false);

    setEmailWrongFormat(false);
    setEmailError(false);
    setCompanyError(false);
    setPasswordError(false);
    setCpasswordError(false);
    setSectorError(false);
    //on clean toutes les erreurs

    const shouldUpdatePassword = (password || cpassword);

    let errorCount = 0;

    if (!email || email.length === 0) {
      setEmailError(true);
      errorCount += 1;
    }
    if (!validateEmail(email)) {
      setEmailWrongFormat(true);
      errorCount += 1;
    }
    if (!company || company.length === 0) {
      setCompanyError(true);
      errorCount += 1;
    }
    if (shouldUpdatePassword && password.length === 0) {
      setPasswordError(true);
      errorCount += 1;
    }
    if (shouldUpdatePassword && cpassword.length === 0) {
      setCpasswordError(true);
      errorCount += 1;
    }
    if (cpassword !== password) {
      setCpasswordError(true);
      errorCount += 1;
    }
    if (!sector || sector.length === 0 || !sectors.includes(sector)) {
      setSectorError(true);
      errorCount += 1;
    }

    if (errorCount > 0) {
      setBackdropOpen(false);
      return;
    }

    const newUserInfo = shouldUpdatePassword ? {
      id: user.id,
      company,
      email,
      password,
      sector,
    } : {
      id: user.id,
      company,
      email,
      sector,
    };

    //On aurait pu mettre un try catch où on aurait isolé les erreurs ds le catch
    const { data } = await userRequests.editProfile(newUserInfo);

    setBackdropOpen(false);

    setSnackbarMessage(data.text);
    setSnackbarOpen(true);

    let postReqErrorCount = 0;

    if (data === "Email existe déjà") {
      //à modifier : on doit avoir une erreur si la personne rentre un email qui est celui d'une autre company (MAIS elle peut conserver son mm email)
      setEmailAlreadyTaken(true);
      postReqErrorCount += 1;
    }
    if (data === "Utilisateur existe déjà") {
      setCompanyAlreadyFiled(true);
      postReqErrorCount += 1;
    }

    if (postReqErrorCount > 0) {
      return;
    }

    await setUser((prevUser) => {
      return {
        id: newUserInfo.id,
        company: newUserInfo.company,
        email: newUserInfo.email,
        isAdmin: prevUser.isAdmin,
        sector: newUserInfo.sector,
      };
    });

    //console.log(data.text)
    //Fin du try
  }

  const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleDisableAccount = async () => {
    setBackdropOpen(true);
    try {
      await userRequests.disableUser(user.id);
      userRequests.logout();
      await setUser(null);
      handleClose();
      props.history.push("/");
    } catch (err) {
      setSnackbarOpen(true);
      setSnackbarMessage("Une erreur est survenue lors de la suppression de votre compte.");
    }
    setBackdropOpen(false);
  };

  return (
    <div
      className="RegistrationForm"
      id="editForm"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnackbarOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <MyBackdrop open={backdropOpen} />

      <Grid item xs={11} sm={9} md={6} style={{ margin: "auto" }}>
        <Card elevation={3}>
          <CardContent style={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Avatar
                  style={{ backgroundColor: "#90d058", margin: "auto" }}
                  className={classes.large}
                >
                  {user.company[0]}
                </Avatar>
              </Grid>
              <Grid item>
                <h1>
                  <nobr>{user.company}</nobr>
                </h1>
              </Grid>
            </Grid>

            <h3>
              Modifiez votre <nobr>compte :</nobr>
            </h3>

            <Grid
              item
              xs={10}
              sm={8}
              md={5}
              style={{ margin: "auto", marginTop: "0.5%" }}
            >
              <MyTextFieldSignUp
                id="email"
                name="email"
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoFocus={false}
                type="email"
                inputIcon={<MailIcon />}
                error={emailError || emailAlreadyTaken || emailWrongFormat}
                emailAlreadyTaken={emailAlreadyTaken}
                emailWrongFormat={emailWrongFormat}
              />
            </Grid>
            <br />

            <Grid item xs={10} sm={8} md={5} style={{ margin: "auto" }}>
              <MyTextFieldSignUp
                id="company"
                name="company"
                label="Association"
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                autoFocus={false}
                type=""
                inputIcon={<BusinessIcon />}
                error={companyError || companyAlreadyFiled}
                companyAlreadyFiled={companyAlreadyFiled}
              />
            </Grid>
            <br />

            <Grid item xs={10} sm={8} md={5} style={{ margin: "auto" }}>
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option}
                onChange={(event, value) => {
                  setSector(value);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Secteur"
                    fullWidth
                    helperText={
                      sectorError ? "Un secteur valide doit être renseigné" : ""
                    }
                    error={sectorError}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                      className: classes.floatingLabelFocusStyle,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      inputMode: "numeric",
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <br />

            <Grid item xs={10} sm={8} md={5} style={{ margin: "auto" }}>
              <MyTextFieldSignUp
                id="password"
                name="password"
                label="Nouveau mot de passe"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                autoFocus={false}
                type="password"
                inputIcon={<LockOpenIcon />}
                error={passwordError}
              />
            </Grid>
            <br />

            <Grid item xs={10} sm={8} md={5} style={{ margin: "auto" }}>
              <MyTextFieldSignUp
                id="cpassword"
                name="cpassword"
                label="Confirmation"
                value={cpassword}
                onChange={(event) => setCpassword(event.target.value)}
                autoFocus={false}
                type="password"
                inputIcon={<LockIcon />}
                error={cpasswordError}
              />
            </Grid>
            <br />
            <br />
            <Button
              variant="contained"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                backgroundColor: "#90d058",
              }}
              endIcon={<SaveIcon />}
              onClick={editProfile}
            >
              <h3>
                <b>Enregistrer les modifications</b>
              </h3>
            </Button>
            <br />

            <Button
              variant="contained"
              color="secondary"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                marginTop: '1rem',
                // backgroundColor: "#90d058",
              }}
              endIcon={<DeleteForever />}
              onClick={handleClickOpen}
            >
              <h3>
                <b>Supprimer mon compte</b>
              </h3>
            </Button>
            <Dialog open={open} onClose={handleClose} >
              <DialogTitle id="simple-dialog-title" >Supprimer mon Compte</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  En cliquant sur "Je confirme", vous supprimerez les données d'e-mail et du nom de votre association associées au diagnostic numérique Solidatech. Vous devrez créer un compte pour compléter le questionnaire de nouveau !
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleDisableAccount} color="secondary">
                  Je confirme
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
