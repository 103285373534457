import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import MyModal from "../../common/MyModal";
import MyTextField from "../../common/forms/MyTextField";

const DEFAULT_TUTORIAL = {
  tutorialName: "",
  tutorialLink: "",
  tutorialDescription: "",
};

export default function ModalFormTutorial({
  tutorialToUpdate = DEFAULT_TUTORIAL,
  isOpen,
  onClose,
  onSubmit,
}) {
  const [tutorial, setTutorial] = useState(tutorialToUpdate);

  /**
   * A l'ouverture de la modal, récupération des solutions numériques et ressources
   */
  useEffect(() => {
    if (isOpen && tutorialToUpdate) {
      setTutorial(tutorialToUpdate);
    }
  }, [tutorialToUpdate, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setTutorial(DEFAULT_TUTORIAL);
    }
  }, [isOpen]);

  const saveTutorial = async () => {
    await onSubmit(tutorial);
    onClose();
  };

  const ActionButtons = () => (
    <>
      <Button onClick={() => saveTutorial()} style={{ color: "#90d058" }}>
        {tutorial?._id ? "Modifier" : "Ajouter"}
      </Button>
      <Button onClick={() => onClose()} style={{ color: "#90d058" }}>
        Annuler
      </Button>
    </>
  );

  return (
    <MyModal
      title={tutorial?._id ? "Modifier la ressource :" : "Ajouter une ressource :"}
      isOpen={isOpen}
      onClose={onClose}
      actions={<ActionButtons />}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        justifyContent="center"
        style={{ minHeight: "200px" }}
      >
        {!isOpen ? (
          <CircularProgress
            disableShrink
            style={{ margin: "auto", color: "rgb(144, 208, 88)" }}
          />
        ) : (
          <>
            <MyTextField
              name="tutorialName"
              label="Nom de la ressource"
              value={tutorial.tutorialName}
              onChange={(event) =>
                setTutorial({
                  ...tutorial,
                  tutorialName: event.target.value,
                })
              }
            />
            <MyTextField
              name="tutorialLink"
              label="Lien"
              value={tutorial.tutorialLink}
              onChange={(event) =>
                setTutorial({
                  ...tutorial,
                  tutorialLink: event.target.value,
                })
              }
            />
            <MyTextField
              label="Description"
              onChange={(event) =>
                setTutorial({
                  ...tutorial,
                  tutorialDescription: event.target.value,
                })
              }
              value={tutorial.tutorialDescription}
              name="tutorialDescription"
              multiline
              minRows={4}
              maxRows={4}
            />
          </>
        )}
      </Grid>
    </MyModal>
  );
}
