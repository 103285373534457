import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import ProductsService from "../../../APIrequests/productRequests";
import Loader from "../../common/Loader";
import ProductsList from "../../products/ProductsList";
import MyBackdrop from "../../common/MyBackdrop";
import MySnackbar from "../../common/MySnackbar";
import MyFloatingButton from "../../common/MyFloatingButton";
import ModalFormProduct from "../../products/ModalFormProduct";

export default function EditProductsPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(null);

  const { width } = useWindowDimensions();

  /**
   * Récupération des solutions numériques pour la page
   */
  useEffect(() => {
    ProductsService.getProducts()
      .then((products) => setProducts(products))
      .catch(() => {
        setSnackbarMessage("Une erreur est survenue.");
        setSnackbarOpen(true);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  /** Gestion de la Modal */

  const openModalFormProduct = (product) => {
    setProductToUpdate(product);
    setModalIsOpen(true);
  };

  const closeModalFormProduct = () => {
    setProductToUpdate(null);
    setModalIsOpen(false);
  };

  /**
   * Supprime la soltion numérique passé en paramètre de la base de données puis en local.
   * @param {*} productToDelete
   */
  const deleteProduct = async (productToDelete) => {
    setBackdropOpen(true);

    try {
      const result = await ProductsService.deleteProduct(productToDelete);

      if (result.data.text !== "Erreur serveur") {
        setProducts([
          ...products.filter((product) => product._id !== productToDelete._id),
        ]);
      }
      setSnackbarMessage(result.data.text);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue.");
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  /**
   * Ajoute la soltion numérique reçu s'il n'existe pas ou l'édite de la base de données.
   * @param {*} product A ajouter ou éditer
   */
  const addOrEditProduct = async (product) => {
    setBackdropOpen(true);
    try {
      let result;
      if (product._id) {
        result = await ProductsService.updateProduct(product);
      } else {
        result = await ProductsService.createProduct(product);
      }
      setSnackbarMessage(result.data.text);
      setProductToUpdate(null);
      const updatedProducts = await ProductsService.getProducts();
      setProducts(updatedProducts);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue");
      return erreur;
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  if (!isLoaded) {
    return <Loader />;
  } else {
    return (
      <div
        style={{
          marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
          marginBottom: isWindowSizeBelowSm()
            ? "110px"
            : "180px" /*overflowY: "scroll"*/,
        }}
      >
        <MyBackdrop open={backdropOpen} />
        <MySnackbar
          message={snackbarMessage}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
        <ProductsList
          products={products}
          deleteProduct={deleteProduct}
          updateProduct={openModalFormProduct}
          isWindowSizeBelowSm={isWindowSizeBelowSm}
        />
        <MyFloatingButton onClick={openModalFormProduct} />
        <ModalFormProduct
          isOpen={modalIsOpen}
          onClose={closeModalFormProduct}
          onSubmit={addOrEditProduct}
          productToUpdate={productToUpdate}
        />
      </div>
    );
  }
}
