import React from "react";
import { makeStyles, Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  margin: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "88px",
      marginRight: "88px",
    },
  },
}));

export function MainFormContent({ children }) {
  const classes = useStyles();
  const tableSizes = {
    xs: 12,
  };
  return (
    <Grid
      item
      className={classes.margin}
      {...tableSizes}
      component={Paper}
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      {children}
    </Grid>
  );
}
