import React, { useState, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Grid, Button, Card, CardContent } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import MyTextFieldLogIn from "../../basicComponents/MyTextFieldLogIn";
import MyBackdrop from "../../common/MyBackdrop";
import userRequests from "../../../APIrequests/userRequests";
import { UserContext } from "../../contexts/UserContext";
import ResetPasswordError from './ResetPasswordError'


export default function ResetPasswordPage() {
  /* Routing */
  let location = useLocation();
  let history = useHistory();

  const [backdropOpen, setBackdropOpen] = useState(false);
  const { setUser } = useContext(UserContext);

  const email = new URLSearchParams(location.search).get('email')
  const token = new URLSearchParams(location.search).get('code')

  if (!email || !token) {
    history.push("/home");
  }

  //pour gérer les erreurs
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false)

  async function resetPassword() {
    setBackdropOpen(true);

    //on clean toutes les erreurs

    let errorCount = 0;

    if (!password || password.length === 0) {
      setPasswordError(true);
      errorCount += 1;
    }

    if (errorCount > 0) {
      setBackdropOpen(false);
      return;
    }

    try {
      try {
        await userRequests.resetPassword({email, password, token});
      } catch (err) {
        if (err.response.status === 403) {
          setBackdropOpen(false);
          return setInvalidLink(true)
        }
        throw err
      }
      const { data: loginData } = await userRequests.login({email, password})

      sessionStorage.removeItem("questAndRes");
      localStorage.setItem("token", loginData.token);
      await setUser(loginData.userInfo);

      setPasswordUpdated(true)

      return null;
    } catch (err) {
      console.error(err)
    }
    setBackdropOpen(false);
  }

  return (
    <div
      className="RegistrationForm"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <MyBackdrop open={backdropOpen} />

      <Grid item xs={11} sm={9} md={6} style={{ margin: "auto" }}>
        <Card elevation={3}>
          <CardContent style={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <h1>Réinitialiser votre mot de passe ?</h1>
            {invalidLink ? <ResetPasswordError /> :
              passwordUpdated ? (<div>
              <p>Le mot de passe a été mis jour avec succès.</p>
                <br />
                <Link
                  style={{textDecoration: 'none'}}
                  to={"/"}
                >
                  <Button
                    variant="contained"
                    style={{
                      paddingLeft: "3%",
                      paddingRight: "3%",
                      backgroundColor: "#90d058",
                    }}
                    onClick={resetPassword}
                  >
                    <h3>
                      <b>Acceuil</b>
                    </h3>
                  </Button>
                </Link>
              </div>) : (
              <div>
                <p>Saisissez votre nouveau mot de passe.</p>
                <br />
                <input type="hidden" name="email" id="email" value={email} ></input>
                <Grid
                  item
                  xs={10}
                  sm={8}
                  md={5}
                  style={{ margin: "auto", marginTop: "0.5%" }}
                >
                  <MyTextFieldLogIn
                    error={!!passwordError}
                    id="password"
                    name="password"
                    label="Mot de passe"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    autoFocus={true}
                    type="password"
                    inputIcon={<LockIcon />}
                  />
                </Grid>
                <br />

                <br />
                <br />

                <Button
                  variant="contained"
                  style={{
                    paddingLeft: "3%",
                    paddingRight: "3%",
                    backgroundColor: "#90d058",
                  }}
                  onClick={resetPassword}
                >
                  <h3>
                    <b>Changez le mot de passe</b>
                  </h3>
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
