import React from "react";
import { Box, Chip, Link, useTheme } from "@material-ui/core";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PdfGeneratorLink from "../PdfGeneratorLink";

export default function SectionActions() {
  const theme = useTheme();

  return (
    <Box py={3}>
      <Box display="flex" justifyContent="center">
        <Link
          target="_blank"
          href="https://airtable.com/shrA1sl7Givsc1VoN?prefill_Catalogue=R%C3%A9aliser+son+diagnostic+de+maturit%C3%A9+num%C3%A9rique"
        >
          <Chip
            icon={<LiveHelpIcon />}
            clickable
            label="Demandez à être accompagné dans vos réflexions sur le numérique"
            style={{
              backgroundColor: theme.palette.common.white,
              padding: "24px",
              borderRadius: "24px",
              fontSize: "1rem",
              cursor: "pointer",
              marginRight: "24px",
            }}
          />
        </Link>
      </Box>
      <Box mt={3} display="flex" justifyContent="center">
        <PdfGeneratorLink />
      </Box>
    </Box>
  );
}
