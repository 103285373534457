import React from "react";
import LuxonUtils from "@date-io/luxon";
import { Interval } from "luxon";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function DateRangePicker({ onChange = () => {}, interval }) {
  const onStartChange = (date) => {
    if (date.isValid) {
      onChange(Interval.fromDateTimes(date, interval.end));
    }
  };

  const onEndChange = (date) => {
    if (date.isValid) {
      onChange(Interval.fromDateTimes(interval.start, date));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-ranger-picker-start"
        label="Depuis"
        onChange={onStartChange}
        value={interval.start}
        KeyboardButtonProps={{
          "aria-label": "depuis",
        }}
      />
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-ranger-picker-end"
        label="à"
        onChange={onEndChange}
        value={interval.end}
        KeyboardButtonProps={{
          "aria-label": "à",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
