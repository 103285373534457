import axios from "axios";

const base_url = new URL(window.location.origin);
base_url.pathname = "";
const burl = base_url.toString().slice(0, -1); // remove trailing slash

const api = axios.create({
  baseURL: burl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
