import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function CircularPercentage({
  percentage,
  size = 50,
  ...props
}) {
  const solidatechTheme = useTheme();

  return (
    <Box
      width={size}
      height={size}
      className="circular-percentage-pdf"
      {...props}
    >
      <CircularProgressbar
        value={percentage}
        text={`${Math.round(percentage)}%`}
        strokeWidth={10}
        styles={{
          path: { stroke: solidatechTheme.palette.primary.main },
          trail: {
            stroke: solidatechTheme.palette.primary.light,
          },
          text: {
            fontSize: "32px",
            fontWeight: "bolder",
            dominantBaseline: "middle",
            textAnchor: "middle",
            fill: solidatechTheme.palette.common.black,
          },
        }}
      />
    </Box>
  );
}
