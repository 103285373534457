import axios from "axios";
import { burl } from "./config";

var headers = {
  "Content-Type": "application/json",
};

export default {
  createProduct: async function (productToAdd) {
    return axios
      .post(`${burl}/product/create`, productToAdd, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        }
      );
  },
  deleteProduct: async function (productToDelete) {
    return axios
      .post(`${burl}/product/delete`, productToDelete, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        }
      );
  },
  getProducts: async function () {
    return axios
      .get(`${burl}/product/getall`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => res.data.products);
  },
  updateProduct: async function (productToUpdate) {
    return axios
      .post(`${burl}/product/update`, productToUpdate, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          console.log(err);
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        }
      );
  },
};
