import React from "react";
import { Grid, Typography } from "@material-ui/core";
import MyStepper from "../../basicComponents/MyStepperSolidaForm";

export function QuestionAnswer(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Grid item xs={ 12 } xl={4}>
        <Typography variant="h5">{props.question.questionText}</Typography>
      </Grid>
      <Grid item xs={ 12 } xl={8}>
        <MyStepper question={props.question} />
      </Grid>
    </Grid>
  );
}
