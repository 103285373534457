import React from "react";
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  button: {
    color: theme.palette.grey[50],
  },
  card: {
    height: "100%",
  },
}));

function AvantageCard({ title, description }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader title={<b>{title}</b>} />
      <Divider />
      <CardContent>
        <Typography variant="subtitle1">{description}</Typography>
      </CardContent>
    </Card>
  );
}

export function PartialResultsCTA() {
  const classes = useStyles();
  const avantageCards = [
    {
      title: "Visualisation interactive",
      description:
        "Découvrez vos résultats sous forme de graphique en toile d'araignée, facile à comprendre et à analyser.",
    },
    {
      title: "Accès et gestion personnalisés",
      description:
        "Modifiez vos réponses et accédez à vos résultats à tout moment via votre e-mail",
    },
    {
      title: "Ressources élargies",
      description:
        "Explorez un éventail de solutions et de ressources dédiées au numérique, sélectionnées en fonction de vos résultats.",
    },
    {
      title: "Partage et exportation faciles",
      description:
        "Partagez vos résultats avec vos collaborateurs et exportez-les au format PDF.",
    },
  ];
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={6}
      >
        <Typography variant="h3">
          Prêt à obtenir un diagnostic numérique complet ?
        </Typography>
        <Box mt={8} textAlign="center">
          <Button
            className={classes.button}
            component="a"
            href="/signup"
            variant="contained"
            color="primary"
            size="large"
          >
            <Typography variant="subtitle1">
              Créer mon compte gratuitement
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box mt={14}>
        <Typography variant="h5">
          En vous inscrivant, bénéficiez de fonctionnalités exclusives :
        </Typography>
        <Box mt={2} />
        <Grid container spacing={2}>
          {avantageCards.map(({ title, description }, index) => (
            <Grid item sm={12} md={6} key={index}>
              <AvantageCard title={title} description={description} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        mt={6}
      >
        <Button
          className={classes.button}
          component="a"
          href="/signup"
          variant="contained"
          color="primary"
          size="large"
        >
          <Typography variant="subtitle1">
            Démarrer le diagnostic numérique complet
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
