export function getAnswerScore(response) {
  switch (response) {
    case 0:
      return 0;
    case 1:
      return 0;
    case 2:
      return 0;
    case 3:
      return 0.5;
    case 4:
      return 1;
    case 5:
      return 0.5;
    default:
      throw Error("Unknown response: " + response);
  }
}
