import React from "react";
import { Fab, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const style = {
  margin: 0,
  top: "auto",
  right: "8%",
  bottom: "6%",
  left: "auto",
  position: "fixed",
};

export default function MyFloatingButton({ onClick }) {
  return (
    <div style={style}>
      <Grid container direction="row" spacing={2} justifyContent="center">
        <Grid item>
          <Fab style={{ backgroundColor: "#90d058", color: "white" }}>
            <AddIcon onClick={() => onClick()} />
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
}
