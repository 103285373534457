import React, { useContext, useEffect, useState, useMemo } from "react";
import CustomizedDialog from "../../basicComponents/MyModalSolidaForm";
import UnansweredDialog from "../../basicComponents/MyModalUnansweredSolidaForm";
import { makeStyles, Grid, Paper, Typography, Box } from "@material-ui/core";
import { MyTable } from "./MyTable";
import IconButton from "@material-ui/core/IconButton";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import HelpIcon from "@material-ui/icons/HelpOutline";

import { FormContext } from "../../contexts/FormContext";
import Loader from "../../common/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  margin: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "88px",
      marginRight: "88px",
    },
  },
}));

export default function FormPage() {
  const {
    themes,
    currentThemeNb,
    retrieveQuestions,
    alreadyModified,
    unanswered,
  } = useContext(FormContext);
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [isLoaded, setIsLoaded] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  useMemo(() => {
    if (isLoaded && !alreadyModified) {
      setDisplayDialog(true);
    } else {
      setDisplayDialog(false);
    }
  }, [isLoaded]);
  useEffect(() => {
    async function getQuestions() {
      await retrieveQuestions();
      setIsLoaded(true);
    }
    getQuestions();
  }, [retrieveQuestions]);

  const onDialogOpen = () => {
    setDisplayDialog(true);
  };

  const tableSizes = {
    xs: 12,
  };
  if (!isLoaded) {
    return <Loader />;
  } else {
    return (
      <div
        className={("SolidaFormPage", classes.root)}
        style={{
          marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
          marginBottom: isWindowSizeBelowSm() ? "110px" : "190px",
          overflowY: "scroll",
        }}
      >
        <CustomizedDialog isOpenState={[displayDialog, setDisplayDialog]} />

        {unanswered !== null ? <UnansweredDialog /> : <></>}

        <Grid
          container
          justifyContent="center"
          style={{ marginBottom: "10px" }}
        >
          <Grid
            component={Paper}
            item
            {...tableSizes}
            className={classes.margin}
            style={{
              backgroundColor: "#90d058",
              color: "white",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              textAlign: "center",
              padding: "1rem",
            }}
          >
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box flexShrink={0}>
                <Typography variant="h3" fontWeight="bold">
                  {themes[currentThemeNb]}
                </Typography>
              </Box>
              <Box alignSelf="flex-start" flexShrink={1}>
                <IconButton
                  style={{ color: "white" }}
                  aria-label="Aide"
                  component="span"
                  onClick={onDialogOpen}
                >
                  <HelpIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            className={classes.margin}
            {...tableSizes}
            component={Paper}
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <MyTable windowHook={isWindowSizeBelowSm} />
          </Grid>
        </Grid>
      </div>
    );
  }
}
