import React from "react";
import { Button } from "@material-ui/core";
import SurveyService from "../../APIrequests/surveyRequests";

export function DownloadSurveyResultsBtn() {
  const download = () => {
    SurveyService.downloadSurveyResult();
  };

  return (
    <Button variant="contained" color="primary" onClick={download}>
      Télécharger les résultats du NPS
    </Button>
  );
}
