import React, { useContext } from "react";
import MyFormNavStepper from "./MyFormNavStepper";
import SponsorList from "./SponsorList";

import { UserContext } from "../contexts/UserContext";

export default function MyFooter() {
  const { user } = useContext(UserContext);

  const isEditAdminUrl = () => {
    return (
      window.location.pathname.substring(0, 5) === "/edit" &&
      user &&
      user.isAdmin === true
    );
  };

  const isResultsPage = () => {
    return (
      window.location.pathname === "/all_results" ||
      window.location.pathname === "/results"
    );
  };

  const isQuickDiagnosticPage = () => {
    return (
      window.location.pathname.substring(0, 11) === "/diagnostic" &&
      window.location.pathname.substring(12).length > 0
    );
  };

  const shouldDisplayFooter = () => {
    if (isEditAdminUrl() || isResultsPage() || isQuickDiagnosticPage()) {
      return false;
    }
    return true;
  };

  return (
    <>
      {shouldDisplayFooter() ? (
        <div className="Footer" style={{ zIndex: 2 }}>
          {window.location.pathname !== "/diagnostic" ? (
            <SponsorList />
          ) : (
            <MyFormNavStepper />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
