import api from "./api";

const dashboardApi = {
  getKeyResults: async (interval) => {
    const response = await api.get(
      `/dashboard/key-results/${interval.start.toISODate()}/${interval.end.toISODate()}`
    );
    return response.data;
  },
  getUsersInfo: async (filters) => {
    const response = await api.get("/dashboard/users-info", {
      params: filters,
    });
    return response.data;
  },
};

export default dashboardApi;
