import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useState,
} from "react";
import { UserContext } from "./UserContext";
import { THEMES_CONFIG } from "../../constants";
import questionsRequests from "../../APIrequests/questionRequests";
import formRequests from "../../APIrequests/formRequests";
import userResponseRequests from "../../APIrequests/userResponseRequests";

const QuickFormContext = createContext({});

const QuickFormAnswersDispatchContext = createContext(null);

export function QuickFormProvider({ children, theme }) {
  const { user } = useContext(UserContext);
  const [answers, dispatch] = useReducer(answersReducer, new Map());
  const [questions, setQuestions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useMemo(() => {
    const answers = loadAnswersFromLocalStorage(theme);
    dispatch({ type: "init", answers });
  }, [theme]);

  function answersReducer(answers, action) {
    switch (action.type) {
      case "init": {
        return action.answers;
      }
      case "reset": {
        answers.set(action.payload.question._id, action.payload.response);
        return new Map(answers);
      }
      case "update": {
        answers.set(action.payload.question._id, action.payload.response);
        if (user) {
          formRequests.updateUserResponse(user.id, {
            questionId: action.payload.question._id,
            response: action.payload.response,
          });
        } else {
          saveAnswersToLocalStorage(theme, answers);
        }
        return new Map(answers);
      }
      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  }

  useMemo(async () => {
    setIsLoaded(false);
    const questions = await questionsRequests.getQuestionsByTheme(theme);
    if (user) {
      const serverAnswers = await userResponseRequests.getUserResponseByUser(
        user.id,
      );
      serverAnswers.userResponse.responses.forEach((serverAnswer) => {
        dispatch({
          type: "reset",
          payload: {
            question: serverAnswer.question,
            response: serverAnswer.response,
          },
        });
      });
    }
    setIsLoaded(true);
    setQuestions(questions);
  }, [theme, user]);

  return (
    <QuickFormContext.Provider value={{ questions, answers }}>
      <QuickFormAnswersDispatchContext.Provider value={dispatch}>
        {isLoaded ? children : null}
      </QuickFormAnswersDispatchContext.Provider>
    </QuickFormContext.Provider>
  );
}

export function useQuickForm() {
  return useContext(QuickFormContext);
}

export function useQuickFormDispatch() {
  return useContext(QuickFormAnswersDispatchContext);
}

function saveAnswersToLocalStorage(theme, answers) {
  localStorage.setItem(theme, JSON.stringify(Object.fromEntries(answers)));
}

function loadAnswersFromLocalStorage(theme) {
  const answers = localStorage.getItem(theme);
  if (answers) {
    return new Map(Object.entries(JSON.parse(answers)));
  } else {
    return new Map();
  }
}

function clearAnswersFromLocalStorage() {
  THEMES_CONFIG.forEach((theme) => {
    localStorage.removeItem(theme.title);
  });
}

export async function mergeAndPersistAnswers(userId) {
  const localAnswers = new Map(
    THEMES_CONFIG.reduce((acc, theme) => {
      const answers = loadAnswersFromLocalStorage(theme.title);
      return [...acc, ...answers];
    }, []),
  );
  if (localAnswers.size === 0) {
    return;
  }
  const serverAnswers =
    await userResponseRequests.getUserResponseByUser(userId);
  let mergedAnswers;
  if (serverAnswers.userResponse?.responses) {
    mergedAnswers = serverAnswers.userResponse.responses.map((serverAnswer) => {
      if (localAnswers.has(serverAnswer.question._id)) {
        return {
          ...serverAnswer,
          response: localAnswers.get(serverAnswer.question._id),
        };
      }
      return serverAnswer;
    });
  } else {
    mergedAnswers = Array.from(localAnswers).map(([questionId, response]) => {
      return {
        question: {
          _id: questionId,
        },
        response,
      };
    });
  }

  await formRequests.updateUserResponses(userId, mergedAnswers);
  clearAnswersFromLocalStorage();
}
