import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function RessourceIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect width="14" height="14" rx="2" fill="#00BFA6" fillOpacity="0.1" />
      <path
        d="M11.9831 10.6579L11.4594 8.59408L9.80955 2.39711C9.77389 2.26042 9.68663 2.14371 9.56687 2.07256C9.4471 2.00141 9.3046 1.98161 9.17057 2.01749L7.14364 2.55217C7.09526 2.49744 7.03633 2.45352 6.97053 2.42315C6.90473 2.39279 6.8335 2.37664 6.7613 2.37573H1.52375C1.38485 2.37573 1.25163 2.43206 1.1534 2.53233C1.05518 2.6326 1 2.7686 1 2.91041V11.4653C1 11.6071 1.05518 11.7431 1.1534 11.8434C1.25163 11.9437 1.38485 12 1.52375 12H6.7613C6.9002 12 7.03342 11.9437 7.13165 11.8434C7.22987 11.7431 7.28505 11.6071 7.28505 11.4653V7.18786L8.43731 11.5829C8.468 11.6996 8.53648 11.8022 8.63158 11.874C8.72669 11.9459 8.84282 11.9827 8.96106 11.9786C9.00631 11.9839 9.05199 11.9839 9.09724 11.9786L11.627 11.2889C11.6939 11.2706 11.7565 11.239 11.8114 11.1959C11.8662 11.1527 11.9121 11.0989 11.9465 11.0376C12.0017 10.9193 12.0147 10.785 11.9831 10.6579ZM3.60306 10.952H2.0318V9.88266H3.60306V10.952ZM3.60306 8.8133H2.0318V5.60521H3.60306V8.8133ZM3.60306 4.53584H2.0318V3.46648H3.60306V4.53584ZM6.22183 10.952H4.65057V9.88266H6.22183V10.952ZM6.22183 8.8133H4.65057V5.60521H6.22183V8.8133ZM6.22183 4.53584H4.65057V3.46648H6.22183V4.53584ZM7.40028 3.60549L8.91916 3.18844L9.19151 4.22038L7.67263 4.63743L7.40028 3.60549ZM8.7568 8.77052L7.94498 5.66937L9.46387 5.25232L10.2757 8.35347L8.7568 8.77052ZM9.28055 10.8344L9.0082 9.80246L10.5271 9.38541L10.7994 10.4173L9.28055 10.8344Z"
        fill="#9E989D"
      />
    </SvgIcon>
  );
}
