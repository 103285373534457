import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

export default function ResetPasswordError() {
  return (
    <div>
      <p>le lien de réinitialisation du mot de passe n'est plus valide.</p>
      <br />
      <Link
        style={{textDecoration: 'none'}}
        to={"/"}
      >
        <Button
          variant="contained"
          style={{
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "#90d058",
          }}
        >
          <h3>
            <b>Retour à l'accueil</b>
          </h3>
        </Button>
      </Link>
    </div>
  )
}
