import React, { useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Grid, Avatar, Button, Card, CardContent } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MailIcon from "@material-ui/icons/Mail";
import LockIcon from "@material-ui/icons/Lock";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import MyTextFieldLogIn from "../../basicComponents/MyTextFieldLogIn";
import MyBackdrop from "../../common/MyBackdrop";
import userRequests from "../../../APIrequests/userRequests";
import { mergeAndPersistAnswers } from "../../contexts/QuickFormContext";

import { UserContext } from "../../contexts/UserContext";

export default function LoginPage() {
  /* Routing */
  let history = useHistory();
  let location = useLocation();

  const [backdropOpen, setBackdropOpen] = useState(false);

  //seul moyen de régler le pb de floatting label qui se superpose au texte input avec material
  var [email, setEmail] = useState("");
  var [password, setPassword] = useState("");

  //pour gérer les erreurs
  var [emailError, setEmailError] = useState(false);
  var [wrongEmailError, setWrongEmailError] = useState(false);
  var [passwordError, setPasswordError] = useState(false);
  var [wrongPasswordError, setWrongPasswordError] = useState(false);

  const { setUser } = useContext(UserContext);

  async function logIn() {
    setBackdropOpen(true);

    setWrongEmailError(false);
    setWrongPasswordError(false);
    setEmailError(false);
    setPasswordError(false);
    //on clean toutes les erreurs

    let errorCount = 0;

    if (!email || email.length === 0) {
      setEmailError(true);
      errorCount += 1;
    }
    if (!password || password.length === 0) {
      setPasswordError(true);
      errorCount += 1;
    }

    if (errorCount > 0) {
      setBackdropOpen(false);
      return;
    }

    const userInfo = {
      email,
      password,
    };
    try {
      const { data } = await userRequests.login(userInfo);

      setBackdropOpen(false);

      let postReqErrorCount = 0;

      if (data === "Mot de passe incorrect, ou l'utilisateur n'existe pas") {
        setWrongPasswordError(true);
        postReqErrorCount += 1;
      }

      if (postReqErrorCount > 0) {
        return;
      }

      localStorage.removeItem("questAndRes");
      localStorage.setItem("token", data.token);
      await setUser(data.userInfo);
      await mergeAndPersistAnswers(data.userInfo.id);

      if (location.state === "redirectToFormAfterConnection") {
        history.push("/diagnostic");
        return null;
      } else {
        history.push("/home");
        return null;
      }
    } catch (err) {
      //console.log(err)
    }
  }

  return (
    <div
      className="RegistrationForm"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <MyBackdrop open={backdropOpen} />

      <Grid item xs={11} sm={9} md={6} style={{ margin: "auto" }}>
        <Card elevation={3}>
          <CardContent style={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <Avatar style={{ backgroundColor: "#90d058", margin: "auto" }}>
              <AccountCircleOutlinedIcon fontSize="large" />
            </Avatar>
            <h1>
              {location.state === "redirectToFormAfterConnection" ? (
                <>Connectez-vous avant de commencer !</>
              ) : (
                <>Connectez-vous :</>
              )}
            </h1>
            <br />

            <Grid
              item
              xs={10}
              sm={8}
              md={5}
              style={{ margin: "auto", marginTop: "0.5%" }}
            >
              <MyTextFieldLogIn
                id="email"
                name="email"
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoFocus={true}
                type="email"
                inputIcon={<MailIcon />}
                error={emailError || wrongEmailError}
                wrongEmailError={wrongEmailError}
              />
            </Grid>
            <br />

            <Grid item xs={10} sm={8} md={5} style={{ margin: "auto" }}>
              <MyTextFieldLogIn
                id="password"
                name="password"
                label="Mot de passe"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                autoFocus={false}
                type="password"
                inputIcon={<LockIcon />}
                error={passwordError || wrongPasswordError}
                wrongPasswordError={wrongPasswordError}
              />
            </Grid>
            <br />
            <br />

            <div style={{ justifyContent: "flex-start" }}>
              Vous ne possédez pas encore un compte ?{" "}
              <Link
                to={
                  location.state === "redirectToFormAfterConnection"
                    ? {
                      pathname: "/signup",
                      state: "redirectToFormAfterConnection",
                    }
                    : "/signup"
                }
              >
                Créez-en un ici.
              </Link>
              <br />
              <br />
              <Link
                to={
                  location.state === "redirectToFormAfterConnection"
                    ? {
                      pathname: "/forgot-password",
                      state: "redirectToFormAfterConnection",
                    }
                    : "/forgot-password"
                }
              >
                J'ai oublié mon mot de passe.
              </Link>
            </div>
            <br />
            <br />

            <Button
              variant="contained"
              style={{
                paddingLeft: "3%",
                paddingRight: "3%",
                backgroundColor: "#90d058",
              }}
              endIcon={<CheckCircleOutlinedIcon />}
              onClick={logIn}
            >
              <h3>
                <b>Connexion</b>
              </h3>
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
