export const SECTORS = [
  "Social",
  "Santé",
  "Humanitaire",
  "Culture",
  "Environnement",
  "Sport",
  "Loisirs",
  "Jeunesse & éducation populaire",
  "Autre",
];

export const THEMES_CONFIG = [
  {
    title: "Animez votre réseau",
    slug: "animation",
    description:
      "L'animation consiste à établir un lien avec votre réseau d'adhérents et de bénévoles, mais également à le faire interagir pour gagner en implication et participation des membres.",
  },
  {
    title: "Gagnez en visibilité sur le web",
    slug: "visibilité",
    description:
      "Être visible sur le web est un vrai enjeu : sensibiliser aux causes de votre association, développer votre notoriété, renforcer vos actions ou trouver de nouvelles ressources (financières, bénévoles, matérielles...).",
  },
  {
    title: "Travaillez collaborativement",
    slug: "collaboration",
    description:
      "Le travail collaboratif consiste à fluidifier le partage d'information et libérer la créativité en équipe. Cela commence par donner à vos membres un accès à un espace de travail partagé pour lire, stocker des fichiers puis à travailler à plusieurs et en simultané sur ces supports numériques.",
  },
  {
    title: "Financez vos projets",
    slug: "financement",
    description:
      "Pour fonctionner et se développer, les associations ont toutes besoin de financement. Le numérique offre de nombreuses opportunités pour optimiser vos recherches de fonds, mobiliser de nouveaux financeurs ou développer de nouvelles sources de revenus.",
  },
  {
    title: "Gérez vos ressources humaines et financières",
    slug: "gestion",
    description:
      "Que ce soit pour accompagner, gérer ou former ses équipes, qu'elles soient salariées ou bénévoles, ou encore pour faire des projections financières, certains outils peuvent vous aider à être plus efficace.",
  },
  {
    title: "Sécurisez votre association et suivez les réglementations",
    slug: "sécurité",
    description:
      "Les risques existent liés à l'utilisation abusive des données personnelles et les cyberattaques existent ! Pour les limiter, les associations sont soumises à des règles. Un ensemble de bonnes pratiques vous aideront à renforcer la sécurité et la protection de vos données.",
  },
  {
    title: "Pilotez la stratégie de votre transformation numérique",
    slug: "stratégie",
    description:
      "La mise en place d'outils numériques amène de nouvelles façons de s'organiser et de travailler. Pour que les outils choisis répondent bien aux objectifs recherchés par votre association, il est fondamental de susciter l'adhésion des futurs utilisateurs et de suivre les changements.",
  },
];

export const THEMES = THEMES_CONFIG.map((theme) => theme.title);

export const THEME_DESCRIPTIONS = THEMES_CONFIG.reduce(
  (acc, theme) => ({ ...acc, [theme.title]: theme.description }),
  {},
);
