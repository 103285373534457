import axios from "axios";
import { burl } from "./config";

var headers = {
  "Content-Type": "application/json",
};

export default {
  createTutorial: async function (tutorialToAdd) {
    return axios
      .post(`${burl}/tutorial/create`, tutorialToAdd, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        }
      );
  },
  deleteTutorial: async function (tutorialToDelete) {
    return axios
      .post(`${burl}/tutorial/delete`, tutorialToDelete, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        }
      );
  },
  getTutorials: async function () {
    return axios
      .get(`${burl}/tutorial/getall`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => res.data.tutorials);
  },
  updateTutorial: async function (tutorialToUpdate) {
    return axios
      .post(`${burl}/tutorial/update`, tutorialToUpdate, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          console.log(err);
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        }
      );
  },
};
