import React, { useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { TableUserRow } from "./TableUserRow";
import { UserContext } from "../../contexts/UserContext";

export function TableUsers({
  users,
  meta,
  onPageChange,
  onRowsPerPageChange,
  onFiltersChange,
  filters,
}) {
  const { sectors } = useContext(UserContext);
  const onSectorChange = (event) => {
    onFiltersChange({ sectors: event.target.value });
  };

  const onSearchChange = (event) => {
    onFiltersChange({ search: event.target.value });
  };

  return (
    <TableContainer component={Paper}>
      <FormControl margin="normal">
        <InputLabel id="sector-chip-label">Secteurs</InputLabel>
        <Select
          labelId="sector-chip-label"
          multiple
          style={{ minWidth: 200 }}
          placeholder="Tous les secteurs"
          input={<Input id="select-multiple-chip" />}
          onChange={onSectorChange}
          value={filters.sectors || []}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Placeholder</em>;
            }
            return selected.map((value) => <Chip key={value} label={value} />);
          }}
        >
          {(sectors || []).map((sector) => (
            <MenuItem value={sector} key={sector}>
              {sector}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl margin="normal" style={{ float: "right" }}>
        <TextField
          id="standard-search"
          onChange={onSearchChange}
          label="Rechercher"
          type="search"
        />
      </FormControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">Nom</TableCell>
            <TableCell component="th" align="right">
              Mail
            </TableCell>
            <TableCell component="th" align="right">
              Secteur
            </TableCell>
            <TableCell component="th" align="right">
              Date de mise a jour
            </TableCell>
            <TableCell component="th" align="right">
              Date de creation
            </TableCell>
            <TableCell component="th" align="right">
              Nombre de question manquante
            </TableCell>
            <TableCell component="th" align="right">
              Score (moyenne)
            </TableCell>
            <TableCell component="th" align="right">
              Voir les résultats
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => {
            return <TableUserRow user={user} key={user.id} />;
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={meta.totalUsers}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPage={meta.limit}
              labelRowsPerPage="Utilisateurs par page"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} sur ${count}`
              }
              page={meta.page}
              onPageChange={(_event, page) => {
                onPageChange(page);
              }}
              onRowsPerPageChange={(event) => {
                const nbRows = parseInt(event.target.value, 10);
                onRowsPerPageChange(nbRows);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
