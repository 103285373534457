import axios from "axios";
import { burl } from "./config";

var headers = {
  "Content-Type": "application/json",
};

export default {
  createQuestion: async function (questionToAdd) {
    return axios
      .post(`${burl}/question/create`, questionToAdd, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        },
      );
  },
  deleteQuestion: async function (questionToDelete, newQuestions) {
    return axios
      .post(
        `${burl}/question/delete`,
        {
          questionToDelete: questionToDelete,
          newQuestions: newQuestions,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .then(
        (res) => res,
        (err) => {
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        },
      );
  },
  getQuestions: async function () {
    return axios
      .get(`${burl}/question`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => res.data.questions);
  },
  getQuestionsByTheme: async function (theme) {
    return axios
      .get(`${burl}/question/themes/${theme.replaceAll("-", " ")}`, {
        headers: {
          ...headers,
        },
      })
      .then((res) => res.data.questions);
  },
  updateQuestion: async function (question) {
    return this.updateQuestions([question]);
  },
  updateQuestions: async function (questionsToUpdate) {
    return axios
      .post(
        `${burl}/question/update`,
        {
          questionsToUpdate: questionsToUpdate,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .then(
        (res) => res,
        (err) => {
          console.log(err);
          const errRes = { data: { text: err.response.data.text } };
          return errRes;
        },
      );
  },
};
