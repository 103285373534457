import React from "react";
import { Button, Grid } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

export default function PhotoSelector({ name, photo, updatePhoto }) {
  const encodeImageFileAsURL = () => {
    var filesSelected = document.getElementById("contained-button-file").files;
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0];
      //console.log(fileToLoad.name)
      //setProductPhotoFilename(fileToLoad.name);

      var fileReader = new FileReader();
      fileReader.onloadend = function () {
        //console.log('RESULT', fileReader.result)
        //setProductPhoto(fileReader.result);
        updatePhoto(fileToLoad.name, fileReader.result);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignContent="center"
      xs={12}
      sm={10}
      spacing={1}
    >
      <Grid item>
        <div
          style={{
            textAlign: "center",
            marginBottom: "5px",
            fontSize: "0.8em",
          }}
        >
          Photo choisie : {photo === "" ? "aucune." : name}
        </div>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={encodeImageFileAsURL}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            style={{ backgroundColor: "#90d058", color: "white" }}
            endIcon={<PhotoCamera />}
          >
            Choisir une photo
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}
