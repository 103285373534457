export const ANSWERS = [
  {
    value: 0,
    label: "Je ne sais pas",
    displayOrder: 5,
  },
  {
    value: 1,
    label: "Hors périmètre",
    displayOrder: 4,
  },
  {
    value: 2,
    label: "Non, mais ça serait utile",
    displayOrder: 3,
  },
  {
    value: 3,
    label: "En cours",
    displayOrder: 2,
  },
  {
    value: 4,
    label: "Oui, c'est satisfaisant",
    displayOrder: 0,
  },
  {
    value: 5,
    label: "Oui, mais c'est à revoir",
    displayOrder: 1,
  }
]
