import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, Button, Card, CardContent } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import MyTextFieldLogIn from "../../basicComponents/MyTextFieldLogIn";
import MyBackdrop from "../../common/MyBackdrop";
import userRequests from "../../../APIrequests/userRequests";

export default function ForgotPasswordPage() {
  /* Routing */
  let location = useLocation();

  const [backdropOpen, setBackdropOpen] = useState(false);

  //seul moyen de régler le pb de floatting label qui se superpose au texte input avec material
  var [email, setEmail] = useState("");

  //pour gérer les erreurs
  var [emailDoesNotExistsError, setEmailDoesNotExistsError] = useState(false);
  var [emailSent, setEmailSent] = useState(false);

  async function sendEmail() {
    setBackdropOpen(true);

    setEmailDoesNotExistsError(false)
    //on clean toutes les erreurs

    let errorCount = 0;

    if (!email || email.length === 0) {
      return;
    }

    if (errorCount > 0) {
      setBackdropOpen(false);
      return;
    }

    try {
      await userRequests.sendResetPasswordEmail(email);
      setEmailSent(true)

    } catch (err) {
      if (err.response.status === 404) {
        setEmailDoesNotExistsError("Aucun compte n'a été créé avec cet email.")
      } else {
        console.error(err)
        throw err
      }
    }
    setBackdropOpen(false);
  }

  return (
    <div
      className="RegistrationForm"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <MyBackdrop open={backdropOpen} />

      <Grid item xs={11} sm={9} md={6} style={{ margin: "auto" }}>
        <Card elevation={3}>
          <CardContent style={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <h1>Mot de passe oublié ?</h1>

            {emailSent ? (<div>
                <p>Nous vous avons envoyé un mail contenant un lien pour réinitialiser votre mot de passe.</p>
                <br />
                <Link
                  style={{textDecoration: 'none'}}
                  to={
                    location.state === "redirectToFormAfterConnection"
                      ? {
                          pathname: "/login",
                          state: "redirectToFormAfterConnection",
                        }
                      : "/login"
                  }
                >
                  <Button
                    variant="contained"
                    style={{
                      paddingLeft: "3%",
                      paddingRight: "3%",
                      backgroundColor: "#90d058",
                    }}
                    onClick={sendEmail}
                  >
                    <h3>
                      <b>Se connecter</b>
                    </h3>
                  </Button>
                </Link>
              </div>) : (
              <div>
                <p>Saisissez votre adresse e-mail pour réinitialiser votre mot de passe.</p>
                <br />
                <Grid
                  item
                  xs={10}
                  sm={8}
                  md={5}
                  style={{ margin: "auto", marginTop: "0.5%" }}
                >
                  <MyTextFieldLogIn
                    id="email"
                    error={!!emailDoesNotExistsError}
                    helperText={emailDoesNotExistsError}
                    name="email"
                    label="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    autoFocus={true}
                    type="email"
                    inputIcon={<MailIcon />}
                  />
                </Grid>
                <br />

                <Link
                  style={{opacity: +!!emailDoesNotExistsError}}
                  to={'signup'}
                >
                  Créer mon compte
                </Link>
                <br />
                <br />

                <Button
                  variant="contained"
                  style={{
                    paddingLeft: "3%",
                    paddingRight: "3%",
                    backgroundColor: "#90d058",
                  }}
                  onClick={sendEmail}
                >
                  <h3>
                    <b>Envoyez</b>
                  </h3>
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
