import axios from "axios";
import { burl } from "./config";

var headers = {
  "Content-Type": "application/json",
};

export default {
  getUserResponses: async function (userId) {
    return axios.get(`${burl}/response/getprevres`, {
      params: userId,
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }); /*.then(res => {console.log(res);return res})*/
  },
  updateUserResponses: async function (userId, questAndResNew) {
    return axios
      .post(
        `${burl}/response/updateres`,
        {
          userId,
          questAndResNew,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .then(() => null);
  },
  updateUserResponse: async function (userId, { questionId, response }) {
    return axios
      .post(
        `${burl}/response/update`,
        {
          userId,
          questionId: questionId,
          response: response,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .then(() => null);
  },
  updateUserResAndComputeResults: async function (userId, questAndRes, themes) {
    return axios.post(
      `${burl}/response/computeresults`,
      {
        userId,
        questAndRes,
        themes,
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    );
  },
};
