import React from "react";
import { Paper, Grid, Typography, List } from "@material-ui/core";
import TutorialItem from "./TutorialItem";

export default function TutorialsList({
  tutorials,
  updateTutorial,
  deleteTutorial,
  isWindowSizeBelowSm,
}) {
  return (
    <Grid item xs={10} sm={9} md={8} xl={6} style={{ margin: "auto" }}>
      <Paper
        elevation={5}
        style={{
          maxWidth: isWindowSizeBelowSm() ? "90vw" : "70vw",
          margin: "auto",
          marginTop: "30px",
          padding: "28px 28px 28px 28px",
        }}
      >
        <Typography component="h4" variant="h4">
          Ressources
        </Typography>
        <List>
          {tutorials.map((tutorial, index) => {
            return (
              <TutorialItem
                key={index}
                tutorial={tutorial}
                updateTutorial={updateTutorial}
                deleteTutorial={deleteTutorial}
                divider={index < tutorials.length - 1}
              />
            );
          })}
        </List>
      </Paper>
    </Grid>
  );
}
