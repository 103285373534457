import React, {useEffect} from "react";
import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  Box,
  useTheme,
  Collapse,
  Button,
  TextField,
  Grid,
  Fade,
  StepIcon,
  StepConnector,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit'
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import SurveyService from "../../../APIrequests/surveyRequests";
import CheckMarkAnimated from "./checkMarkAnimated";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cellFont: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85em",
    },
  },
}));

export default function Survey() {

  const theme = useTheme();
  const classes = useStyles();
  const steps = ['Très improbable', '', '', '', '', '', '', '', '', 'Très probable'];
  const { width } = useWindowDimensions();
  const [activeStep, setActiveStep] = React.useState(undefined);
  const [comment, setComment] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true)
  const [isEdit, setIsEdit] = React.useState(true)

  useEffect(() => {
    SurveyService.getSurvey().then((survey) => {
      if (survey.score) {
        setActiveStep(survey.score - 1);
      }
      if (survey.comment) {
        setComment(survey.comment)
        setIsEdit(false)
      }
      setIsLoading(false)
    })
  }, [])

  const handleStep = (step) => () => {
    SurveyService.updateSurvey({score: step + 1})
    setActiveStep(step);
  };

  const saveComment = () => {
    SurveyService.updateSurvey({comment})
    setIsEdit(false)
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const showActiveIcon = (index) => {
    if (index === activeStep) {
      return styledActiveStepIcon;
    } else {
      return styledStepIcon;
    }
  };
  const isWindowSizeBelowSm = () => {
    if (width < 800) {
      return true;
    } else {
      return false;
    }
  };

  const styledStepIcon = withStyles({
    root: {
      fill: theme.palette.grey['200'],
      'border-style': 'solid',
      'border-width': '1px',
      'border-radius': '100%',
      borderColor: theme.palette.grey['400']
    },
  })(StepIcon);

  const styledActiveStepIcon = withStyles({
    text: {
      fill: 'white'
    },
    root: {
      fill: theme.palette.primary.main,
      'border-style': 'none',
    },
  })(StepIcon);

  if (isLoading) {
    return null
  }

  return (
    <Box mt={4}>
      <Typography variant="h4" component="h4" color="textPrimary">
        Recommanderiez-vous notre outil d'autodiagnostic numérique à une autre association ?
      </Typography>
      <Box
        mt={3} p={2} mb={1}
        style={{
          backgroundColor: theme.palette.background.paper,
          position: 'relative'
        }}
      >
        <Fade in={!isEdit} style={{
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 2,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}>
          <Grid direction="column" container justifyContent="space-around" alignItems="center">
            <Box style={{height: '100px'}}>
              { isEdit ? null :<CheckMarkAnimated /> }
            </Box>
            <Typography variant="h4">Merci beaucoup pour votre retour !</Typography>
            <Button variant="contained"  startIcon={<EditIcon />} onClick={() => setIsEdit(true)} >
              Modifiez mon évaluation
            </Button>
          </Grid>

        </Fade>
        <Box style={{opacity: isEdit ? 1 : 0}}>
          <Stepper
            alternativeLabel={true}
            nonLinear
            connector={isWindowSizeBelowSm() ? null : <StepConnector />}
            orientation="horizontal"
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const buttonProps = {};

              return (
                <Step key={index} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    /*completed={isStepComplete(index)}*/
                    {...buttonProps}
                  >
                    <StepLabel
                      StepIconComponent={showActiveIcon(index)}
                      style={{
                        textAlign: isWindowSizeBelowSm() ? "left" : "center",
                      }}
                    >
                      <div className={classes.cellFont}>
                        <Typography variant="body1">{label}</Typography>
                      </div>
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <Collapse in={activeStep !== undefined} collapsedSize={0}>
            <TextField
              id="outlined-multiline-static"
              label="Pourquoi avez-vous attribué cette note ?"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              m={2}
              onChange={handleCommentChange}
              value={comment}
            />
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Box mt={2}>
                <Button variant="contained" color="primary" startIcon={<SendIcon />} onClick={saveComment} >
                  Envoyez
                </Button>
              </Box>
            </Grid>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}
