import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialog({ isOpenState }) {
  const [open, setOpen] = isOpenState;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h5">
            Conseils pour réaliser un diagnostic précis
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Au travers du questionnaire, vous pourrez répondre : “Je ne sais
            pas”, “Hors périmètre”, “Non, mais ça serait utile”, “En cours”,
            "Oui, mais c'est à revoir", “Oui, c'est satisfaisant”.
          </Typography>
          <br />
          <Typography>
            Choisissez la réponse qui correspond le mieux à votre analyse de la
            situation.
            <br />
            Par exemple à la question "Avez-vous mis en place un réseau social
            interne ?", vous répondrez :
          </Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <strong>Oui, c'est satisfaisant</strong>, si : “Tout est ok, ça
                fonctionne en adéquation avec nos objectifs." ; "Il n’y a rien à
                changer ni prévoir.”
              </li>
              <li>
                <strong>En cours</strong>, si : “Le chantier avance bien." ;
                "Des améliorations sont déjà en cours de mise en place."
              </li>
              <li>
                <strong>Non, mais ça serait utile</strong>, si : “On n’y avait
                pas pensé, mais c’est une bonne idée !” ; "Il faut le revoir,
                car au vu de nos objectifs, cela ne fonctionne pas encore
                parfaitement."
              </li>
              <li>
                <strong>Hors périmètre</strong>, si : “Cela n'est pas pertinent
                au vu de nos objectifs et de nos usages."
              </li>
            </ul>
          </Typography>
          <Typography>
            Les situations entre associations varient. Focalisez-vous sur votre
            contexte et vos objectifs afin d’obtenir des résultats correspondant
            à la réalité de votre structure !
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Commencez !
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
