import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Typography,
  useTheme,
} from "@material-ui/core";
import RessourceIcon from "./SVG/RessourceIcon";
import SolutionNumeriqueIcon from "./SVG/SolutionNumeriqueIcon";

export default function CarouselCard({ element, type }) {
  const theme = useTheme();
  const isProduct = type === "product";

  return (
    <Card
      elevation={0}
      style={{
        margin: "0 8px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardActionArea
        href={isProduct ? element.productLink : element.tutorialLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ flexGrow: 1 }}
      >
        <CardContent>
          <Box display="flex" color={theme.palette.grey.darkGrey}>
            <Box>
              {isProduct ? (
                <SolutionNumeriqueIcon viewBox="0 0 14 14" />
              ) : (
                <RessourceIcon viewBox="0 0 14 14" />
              )}
            </Box>
            <Box pl={1}>
              <Typography
                component="span"
                style={{ textTransform: "uppercase" }}
              >
                {isProduct ? "Solution numérique" : "Ressources"}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" my={1}>
            {isProduct && element.productPhoto && (
              <Box pr={1}>
                <Avatar
                  variant="square"
                  src={element.productPhoto}
                  alt={element.productPhoto}
                />
              </Box>
            )}
            <Box pr={1}>
              <Typography variant="h5">
                {isProduct ? element.productName : element.tutorialName}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography>
              {isProduct
                ? element.productDescription
                : element.tutorialDescription}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ backgroundColor: theme.palette.grey.lightGrey }}>
        <Chip
          style={{ backgroundColor: theme.palette.common.white }}
          label={element.toolType}
        />
      </CardActions>
    </Card>
  );
}
