import React, { useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import ModalUserResponse from "./ModalUserResponse";

export default function IndividualResultsItem({
  userResponse,
  deleteUserResponse,
  divider,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <ModalUserResponse
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        userResponse={userResponse}
      />

      <ListItem>
        <ListItemText
          primary={
            <>
              <Typography variant="h5">
                {userResponse.user?.company ? (
                  userResponse.user.company
                ) : (
                  <i>Sans nom d'association</i>
                )}
              </Typography>
              {userResponse.responses.some(
                (response) => response.response === null
              ) && (
                <Box fontStyle="italic" color="grey.darkGrey">
                  <Typography variant="body1">(en cours)</Typography>
                </Box>
              )}
            </>
          }
          secondary={
            <Box color="grey.darkGrey">
              <Typography variant="body1">
                <b>Secteur :</b>{" "}
                {userResponse.user?.sector ? (
                  userResponse.user.sector
                ) : (
                  <i>Non défini</i>
                )}
                <br />
                <b>Créé le :</b> {userResponse.createdAt.substring(8, 10)}/
                {userResponse.createdAt.substring(5, 7)}/
                {userResponse.createdAt.substring(0, 4)} à{" "}
                {Number(userResponse.createdAt.substring(11, 13)) + 2}h
                {userResponse.createdAt.substring(14, 16)}
                <br />
                <b>Modifié en dernier le :</b>{" "}
                {userResponse.lastModified[
                  userResponse.lastModified.length - 1
                ].substring(8, 10)}
                /
                {userResponse.lastModified[
                  userResponse.lastModified.length - 1
                ].substring(5, 7)}
                /
                {userResponse.lastModified[
                  userResponse.lastModified.length - 1
                ].substring(0, 4)}
                &nbsp;à{" "}
                {Number(
                  userResponse.lastModified[
                    userResponse.lastModified.length - 1
                  ].substring(11, 13)
                ) + 2}
                h
                {userResponse.lastModified[
                  userResponse.lastModified.length - 1
                ].substring(14, 16)}
                <br />
              </Typography>
            </Box>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            edge="end"
            onClick={() => setDialogOpen(true)}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            size="small"
            edge="end"
            onClick={() => deleteUserResponse(userResponse)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {divider && <Divider component="li" />}
    </>
  );
}
