import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";

import QuestionsService from "../../../APIrequests/questionRequests";
import Loader from "../../common/Loader";
import ThemeQuestionsList from "../../questions/ThemeQuestionsList";
import MyBackdrop from "../../common/MyBackdrop";
import MySnackbar from "../../common/MySnackbar";
import MyFloatingButton from "../../common/MyFloatingButton";
import ModalFormQuestion from "../../questions/ModalFormQuestion";

import { THEMES } from "../../../constants";

export default function EditQuestionsPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [questions, setQuestions] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [questionToUpdate, setQuestionToUpdate] = useState(null);

  const { width } = useWindowDimensions();

  /**
   * Récupération des questions pour la page
   */
  useEffect(() => {
    QuestionsService.getQuestions()
      .then((questions) => {
        setQuestions(questions);
      })
      .catch((erreur) => {
        setSnackbarMessage("Une erreur est survenue.");
        setSnackbarOpen(true);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  /** Gestion de la Modal */

  const openModalFormQuestion = (question) => {
    setQuestionToUpdate(question);
    setModalIsOpen(true);
  };

  const closeModalFormQuestion = () => {
    setQuestionToUpdate(null);
    setModalIsOpen(false);
  };

  /**
   * Traitement du changement d'ordre des questions.
   * Les questions impactés seront toutes mises à jour avec leur nouvel ordre.
   * Récupère à nouveau les questions à jour pour la page.
   * @param {*} listQuestionsToUpdate Liste des questions à mettre à jour en base.
   * @returns
   */
  const changeOrderQuestions = async (listQuestionsToUpdate) => {
    setBackdropOpen(true);
    try {
      const result = await QuestionsService.updateQuestions(
        listQuestionsToUpdate
      );
      setSnackbarMessage(result.data.text);
      const updatedQuestions = await QuestionsService.getQuestions();
      setQuestions(updatedQuestions);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue");
      return erreur;
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  /**
   * Supprime la question passée en paramètre de la base de données puis en local.
   * @param {*} questionToDelete
   */
  const deleteQuestion = async (questionToDelete) => {
    setBackdropOpen(true);

    let newThemeQuestions = questions
      .filter((question) => question.theme === questionToDelete.theme)
      .filter((question) => question._id !== questionToDelete._id);

    newThemeQuestions.forEach((question, newIndex) => {
      question.order = newIndex + 1;
    });

    try {
      const result = await QuestionsService.deleteQuestion(
        questionToDelete,
        newThemeQuestions
      );

      if (result.data.text !== "Erreur serveur") {
        // Confirmation suppression
        setQuestions([
          ...questions.filter(
            (question) => question.theme !== questionToDelete.theme
          ),
          ...newThemeQuestions,
        ]);
      }
      setSnackbarMessage(result.data.text);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue.");
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  /**
   * Ajoute la question reçue si elle n'existe pas ou l'édite de la base de données.
   * Récupère à nouveau les questions à jour pour la page.
   * @param {*} question A ajouter ou éditer
   */
  const addOrEditQuestion = async (question) => {
    setBackdropOpen(true);
    try {
      let result;
      if (question._id) {
        result = await QuestionsService.updateQuestion(question);
      } else {
        result = await QuestionsService.createQuestion(question);
      }
      setSnackbarMessage(result.data.text);
      setQuestionToUpdate(null);
      const updatedQuestions = await QuestionsService.getQuestions();
      setQuestions(updatedQuestions);
    } catch (erreur) {
      setSnackbarMessage("Une erreur est survenue");
      return erreur;
    } finally {
      setBackdropOpen(false);
      setSnackbarOpen(true);
    }
  };

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  if (!isLoaded) {
    return <Loader />;
  } else {
    return (
      <div
        //className="SolidaFormPage"
        style={{
          marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
          marginBottom: isWindowSizeBelowSm()
            ? "110px"
            : "180px" /*overflowY: "scroll"*/,
        }}
      >
        <MyBackdrop open={backdropOpen} />
        <MySnackbar
          message={snackbarMessage}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
        {THEMES.map((theme, index) => {
          return (
            <ThemeQuestionsList
              theme={theme}
              questions={questions.filter(
                (question) => question.theme === theme
              )}
              deleteQuestion={deleteQuestion}
              updateQuestion={openModalFormQuestion}
              changeOrderQuestions={changeOrderQuestions}
              windowHook={isWindowSizeBelowSm}
              key={index}
            />
          );
        })}
        <MyFloatingButton onClick={openModalFormQuestion} />

        <ModalFormQuestion
          isOpen={modalIsOpen}
          onClose={closeModalFormQuestion}
          onSubmit={addOrEditQuestion}
          questionToUpdate={questionToUpdate}
        />
      </div>
    );
  }
}
