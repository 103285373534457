import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  cssLabel: {
    color: "#90d058 !important",
  },
  floatingLabelFocusStyle: {
    color: "#90d058 !important",
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: "1px",
  },
}));

export default function ChipSelector({
  title,
  type,
  actualList,
  allList,
  updateList,
  labelKey,
}) {
  /* Style */
  const classes = useStyles();

  const [chipToAdd, setChipToAdd] = useState("");
  const [resetAutocompleteValue, setResetAutocompleteValue] = useState(false);

  const addChip = (newChip) => {
    if (!actualList.includes(newChip)) {
      updateList([...actualList, newChip]);
      setChipToAdd(null);
      setResetAutocompleteValue((prev) => !prev);
    }
  };

  const deleteChip = (indexChipToDelete) => {
    if (actualList.length > 0) {
      const newList = [...actualList];
      newList.splice(indexChipToDelete, 1);
      updateList(newList);
    }
  };

  return (
    <Grid item container spacing={1} xs={12} sm={10}>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>

      <Grid item style={{ margin: "auto" }}>
        <div className={classes.root}>
          {actualList.length === 0 ? (
            <i style={{ fontSize: "12px" }}>
              Pas {type ? `de ${type}` : "d'élément"} ajouté pour le moment
            </i>
          ) : (
            actualList.map((chip, index) => {
              return (
                <Chip
                  label={chip[labelKey]}
                  onDelete={() => deleteChip(index)}
                  style={{
                    backgroundColor: "rgba(213,213,213,0.5)",
                  }}
                  key={index}
                />
              );
            })
          )}
        </div>
      </Grid>
      <Grid item style={{ margin: "auto" }}>
        <nobr style={{ display: "flex" }}>
          <Autocomplete
            key={resetAutocompleteValue}
            options={allList === null ? [] : allList}
            getOptionLabel={(option) => option[labelKey]}
            onChange={(event, value) => {
              setChipToAdd(value);
            }}
            filterSelectedOptions
            loading={!allList.length}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="A ajouter"
                fullWidth
                InputLabelProps={{
                  ...params.InputLabelProps,
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: "numeric",
                  endAdornment: (
                    <>
                      {!allList.length && (
                        <CircularProgress
                          disableShrink
                          style={{ color: "rgb(144, 208, 88)" }}
                          size={20}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                style={{ width: 300 }}
              />
            )}
          />
          <IconButton onClick={() => addChip(chipToAdd)}>
            <AddIcon />
          </IconButton>
        </nobr>
      </Grid>
    </Grid>
  );
}
