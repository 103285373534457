import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyModal({ title, isOpen, onClose, actions, children }) {
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="sm"
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-slide-title"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
