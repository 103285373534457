import React from "react";
import { ResponsivePie } from "@nivo/pie";

export function NpsPieChart({ scores }) {
  const data = [...Array(10).keys()].map((_, index) => {
    const score = scores.find((score) => score.label === index + 1);
    return {
      id: `${index + 1} / 10`,
      label: `${index + 1} / 10`,
      value: score?.count || 0,
    };
  });
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 30, right: 0, bottom: 30, left: 20 }}
      innerRadius={0.3}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: "red_yellow_green" }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      padAngle={1}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      legends={[
        {
          anchor: "left",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 25,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
}
