import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Paper, Typography, Grid, List } from "@material-ui/core";
import QuestionItem from "./QuestionItem";

export default function ThemeQuestionsList({
  theme,
  questions,
  changeOrderQuestions,
  updateQuestion,
  deleteQuestion,
  windowHook,
}) {
  const isWindowSizeBelowSm = windowHook;

  const [themeQuestions, setThemeQuestions] = useState([]);

  /**
   * Trie dans l'ordre les questions reçu par le composant
   */
  useEffect(() => {
    setThemeQuestions(questions.sort((q1, q2) => q1.order - q2.order));
  }, [questions]);

  /**
   * Déclenché après chaque drop de question.
   * Effectue le changement d'ordre des questions en local et dans la base de données.
   * @param {*} result
   */
  const handleDragEnd = async (result) => {
    const { destination, source } = result;

    if (
      destination &&
      destination.droppableId === source.droppableId &&
      destination.index !== source.index
    ) {
      // Changement de position en local des questions
      let newThemeQuestions = [...themeQuestions];
      const [movedQuestion] = newThemeQuestions.splice(source.index, 1);

      newThemeQuestions.splice(destination.index, 0, movedQuestion);
      newThemeQuestions.forEach((question, newIndex) => {
        question.order = newIndex + 1;
      });
      /* setThemeQuestions(newThemeQuestions); */
      // Mise à jour côté base de données
      await changeOrderQuestions(newThemeQuestions);
    }
  };

  if (!themeQuestions.length) {
    return null;
  } else {
    return (
      <Grid item xs={11} sm={9} md={8} style={{ margin: "auto" }}>
        <Paper
          elevation={5}
          style={{
            maxWidth: isWindowSizeBelowSm() ? "90vw" : "70vw",
            margin: "auto",
            marginTop: "30px",
            padding: "28px 28px 28px 28px",
          }}
        >
          <Typography component="h4" variant="h4">
            {theme}
          </Typography>
          <br />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={theme}>
              {(provided) => (
                <List>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    justifyContent="center"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {themeQuestions.map((question, index) => (
                      <QuestionItem
                        question={question}
                        deleteQuestion={deleteQuestion}
                        updateQuestion={updateQuestion}
                        index={index}
                        key={index}
                      />
                    ))}
                    {provided.placeholder}
                  </Grid>
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Paper>
      </Grid>
    );
  }
}
