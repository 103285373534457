import React from "react";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import { Grid } from "@material-ui/core";
import IndividualResultsList from "../../resultsAnalysis/IndividualResultsList";
/* import ScoresAnalysisBoard from "../../resultsAnalysis/ScoresAnalysisBoard";
import UsageAnalysisBoard from "../../resultsAnalysis/UsageAnalysisBoard"; */

export default function ResultsAnalysisPage() {
  const { width } = useWindowDimensions();

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
        marginBottom: isWindowSizeBelowSm() ? "110px" : "180px",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        {/* S139 : Désactivation de cette portion */}
        {/* <Grid xs={10} item container spacing={1} justifyContent="center">
          <Grid item container md={6}>
            <ScoresAnalysisBoard />
          </Grid>
          <Grid item container md={6}>
            <UsageAnalysisBoard />
          </Grid>
        </Grid> */}

        <Grid item container xs={10} style={{ padding: "8px" }}>
          <IndividualResultsList windowHook={isWindowSizeBelowSm} />
        </Grid>
      </Grid>
    </div>
  );
}
