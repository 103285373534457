import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularPercentage from "../CircularPercentage";
import { PillarDetails } from "../PillarDetails";

const useStyles = makeStyles({
  accordionRoot: {
    position: "inherit",
    marginBottom: "16px",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
  },
  accordionSummaryContent: {
    display: "block",
  },
  break: {
    flexBasis: "100%",
    height: "0",
  },
  expanded: {
    marginTop: "0 !important",
    marginBottom: "16px",
  },
});

export default function AccordionItem({
  theme,
  description,
  responses = [],
  score = undefined,
  defaultExpanded = false,
}) {
  const classes = useStyles();

  if (!theme || !description) {
    return null;
  } else {
    return (
      <Accordion
        square
        elevation={0}
        classes={{ root: classes.accordionRoot, expanded: classes.expanded }}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{ color: "primary", edge: "start" }}
          className={classes.accordionSummary}
          classes={{
            content: classes.accordionSummaryContent,
          }}
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography
                variant="h6"
                component="h6"
                style={{ textTransform: "uppercase" }}
              >
                {theme}
              </Typography>
              <div className={classes.break} />
              <Typography>{description}</Typography>
            </Grid>
            <Grid item xs={1}>
              {typeof score === "number" && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="right"
                  style={{ margin: "auto", height: "100%" }}
                >
                  <CircularPercentage percentage={Math.round(score)} />
                </Box>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <PillarDetails responses={responses} />
        </AccordionDetails>
      </Accordion>
    );
  }
}
