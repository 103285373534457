import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Interval, DateTime } from "luxon";

import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import DateRangePicker from "../../common/DateRangePicker";
import KeyResults from "../../dashboard/KeyResults";
import dashboardApi from "../../../APIrequests/dashboardRequest";
import { TableUsers } from "../../dashboard/TableUsers";
import { DownloadSurveyResultsBtn } from "../../dashboard/DownloadSurveyResultsBtn";

export default function DashboardPage() {
  const [interval, setInterval] = useState(
    Interval.fromDateTimes(
      DateTime.now().minus({ month: 1 }).startOf("day"),
      DateTime.now().endOf("day"),
    ),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [keyResults, setKeyResults] = useState();
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
    sectors: [],
  });
  const [usersInfoResults, setUsersInfoResult] = useState(null);
  const { width } = useWindowDimensions();

  const onDateChange = (interval) => {
    setInterval(interval);
  };

  const onUserPageChange = (page) => {
    setFilters({ ...filters, page: page });
  };

  const onUserRowsPerPageChange = (limit) => {
    setFilters({ ...filters, limit: limit });
  };

  const onUserFiltersChange = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const isWindowSizeBelowSm = () => {
    if (width < 1100) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const fetchKeyResults = async () => {
      try {
        if (interval.isValid) {
          const response = await dashboardApi.getKeyResults(interval);
          setKeyResults(response);
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    };
    fetchKeyResults();
  }, [interval, isLoading]);

  useEffect(() => {
    const fetchUsersInfo = async () => {
      try {
        const response = await dashboardApi.getUsersInfo(filters);
        setUsersInfoResult(response);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsersInfo();
  }, [filters]);

  return (
    <div
      style={{
        marginTop: isWindowSizeBelowSm() ? "90px" : "100px",
        marginBottom: isWindowSizeBelowSm() ? "110px" : "190px",
        overflowY: "scroll",
      }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          component={Paper}
          xs={12}
          md={10}
          style={{ padding: "24px" }}
        >
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3" fontWeight="bold">
                Tableau de bord
              </Typography>
            </Grid>
            <Grid item>
              <DateRangePicker
                onChange={onDateChange}
                interval={interval}
              ></DateRangePicker>
            </Grid>
          </Grid>
          <KeyResults
            isLoading={isLoading}
            keyResults={keyResults}
          ></KeyResults>
        </Grid>
        <Grid container justifyContent="center">
          <Grid
            item
            component={Paper}
            xs={12}
            md={10}
            style={{ padding: "24px", marginTop: "24px" }}
          >
            <Typography variant="h3" fontWeight="bold"></Typography>
            {usersInfoResults ? (
              <TableUsers
                users={usersInfoResults.users}
                meta={usersInfoResults.meta}
                filters={filters}
                onPageChange={onUserPageChange}
                onRowsPerPageChange={onUserRowsPerPageChange}
                onFiltersChange={onUserFiltersChange}
              />
            ) : null}
          </Grid>
          <Grid container justifyContent="center">
            <Box m={2}>
              <DownloadSurveyResultsBtn />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
