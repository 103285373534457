import React from "react";
import {
  Card,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import DeleteButton from "../../../common/forms/DeleteButton";

export default function QuestionItem({
  question,
  index,
  updateQuestion,
  deleteQuestion,
}) {
  return (
    <Draggable draggableId={index.toString()} index={question.order - 1}>
      {(provided, snapshot) => (
        <Grid
          item
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Card
            style={{
              backgroundColor: snapshot.isDragging
                ? "#90d058"
                : "rgba(213,213,213,0.5)",
            }}
          >
            <ListItem>
              <ListItemText
                style={{
                  paddingRight: "25px",
                  color: snapshot.isDragging ? "white" : "black",
                }}
              >
                {question.questionText}
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => updateQuestion(question)}
                >
                  <EditIcon
                    style={{ color: snapshot.isDragging ? "white" : "" }}
                  />
                </IconButton>
                <DeleteButton
                  deleteElement={() => deleteQuestion(question)}
                  elementTitre={question.questionText}
                  iconColor={snapshot.isDragging ? "white" : ""}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </Card>
        </Grid>
      )}
    </Draggable>
  );
}
