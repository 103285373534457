import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import UserResultService from "../../APIrequests/userResponseRequests";
import { ResultsPdfGenerator } from "../../services/ResultsPdfGenerator";
import { Chip, Link, useTheme } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";

export default function PdfGeneratorLink() {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const onClick = async (event) => {
    event.preventDefault();
    const userResponse = await UserResultService.getUserResponseByUser(user.id);
    ResultsPdfGenerator(userResponse);
  };
  return (
    <Link style={{}} onClick={onClick}>
      <Chip
        icon={<CloudDownload />}
        clickable
        label="Partagez vos résultats en format PDF"
        style={{
          backgroundColor: theme.palette.common.white,
          padding: "24px",
          borderRadius: "24px",
          fontSize: "1rem",
          cursor: "pointer",
        }}
      />
    </Link>
  );
}
