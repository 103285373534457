import React, { useEffect } from "react";
import { ANSWERS } from "../../contants";
import { makeStyles } from "@material-ui/core/styles";
import {
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

function MyRadioButtonCheckedIcon() {
  return (
    <RadioButtonCheckedIcon
      style={{ color: "#90d058" }}
    ></RadioButtonCheckedIcon>
  );
}

function MyRadioButtonUncheckedIcon() {
  return (
    <RadioButtonUncheckedIcon
      style={{ color: "lightgrey" }}
    ></RadioButtonUncheckedIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cellFont: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85em",
    },
  },
}));

function getSteps() {
  return ANSWERS.sort((answerA, answerB) => {
    return answerA.displayOrder - answerB.displayOrder;
  }).map(({ label }) => {
    return label;
  });
}

export function MainFormQuestionStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(props.answer);
  const { width } = useWindowDimensions();

  const steps = getSteps();

  useEffect(() => {
    setActiveStep(
      ANSWERS.find((answer) => answer.value === props.answer)?.displayOrder,
    );
  }, [props.question, props.answer]);

  const handleStep = (step) => () => {
    const selectedAnswer = ANSWERS.find((answer) => {
      return answer.displayOrder === step;
    });
    if (typeof props.onUpdate === "function") {
      props.onUpdate(selectedAnswer.value);
    }
    setActiveStep(step);
  };

  const showActiveIcon = (index) => {
    if (index === activeStep) {
      return MyRadioButtonCheckedIcon;
    } else {
      return MyRadioButtonUncheckedIcon;
    }
  };

  const isWindowSizeBelowSm = () => {
    if (width < 950) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel={!isWindowSizeBelowSm()}
        nonLinear
        connector={isWindowSizeBelowSm() ? false : <StepConnector />}
        orientation={isWindowSizeBelowSm() ? "vertical" : "horizontal"}
        activeStep={activeStep}
        style={{ paddingBottom: "8px" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepButton onClick={handleStep(index)} {...buttonProps}>
                <StepLabel
                  style={{
                    textAlign: isWindowSizeBelowSm() ? "left" : "center",
                  }}
                  StepIconComponent={showActiveIcon(index)}
                >
                  <div className={classes.cellFont}>
                    <Typography variant="body1">{label}</Typography>
                  </div>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
