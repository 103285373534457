import React from "react";
import { EditProfileForm } from "./EditProfileForm";

export default function EditProfilePage(props) {
  return (
    <div>
      <EditProfileForm {...props} />
    </div>
  );
}
