import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { QuickFormProvider } from "../../contexts/QuickFormContext";
import { THEMES_CONFIG } from "../../../constants";
import {
  MainFormHeader,
  MainFormWrapper,
  MainFormContent,
  MainFormQuestionList,
  MainFormCallToAction,
  MainFormPartialDiagnosticModal,
} from "../../mainForm";

export default function QuickDiagnosticPage() {
  const params = useParams();
  let history = useHistory();

  const theme = THEMES_CONFIG.find(
    (theme) => theme.slug.toLowerCase() === params.theme.toLowerCase(),
  );
  if (!theme) {
    setTimeout(() => history.push("/"), 0);
    return null;
  }

  return (
    <QuickFormProvider theme={theme.title}>
      <MainFormPartialDiagnosticModal
        theme={theme.title}
        description={theme.description}
      />
      <MainFormWrapper>
        <MainFormHeader>{theme.title}</MainFormHeader>
        <MainFormContent>
          <MainFormQuestionList />
        </MainFormContent>
        <MainFormCallToAction themeSlug={theme.slug} />
      </MainFormWrapper>
    </QuickFormProvider>
  );
}
