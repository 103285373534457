import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";
import { THEMES } from "../../../constants";
import RessourcesService from "../../../APIrequests/ressourceRequests";
import ChipSelector from "../../common/forms/ChipSelector";
import MyModal from "../../common/MyModal";
import MyTextField from "../../common/forms/MyTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  cssLabel: {
    color: "#90d058 !important",
  },
  floatingLabelFocusStyle: {
    color: "#90d058 !important",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#90d058 !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
  },
}));

const DEFAULT_QUESTION = {
  questionText: "",
  theme: "",
  products: [],
  tutorials: [],
};

export default function ModalFormQuestion({
  questionToUpdate = DEFAULT_QUESTION,
  isOpen,
  onClose,
  onSubmit,
}) {
  /* Style */
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [question, setQuestion] = useState(questionToUpdate);
  const [allProducts, setAllProducts] = useState([]);
  const [allTutorials, setAllTutorials] = useState([]);

  /**
   * A l'ouverture de la modal, récupération des solutions numériques et ressources
   */
  useEffect(() => {
    if (isOpen && questionToUpdate) {
      RessourcesService.getAllRessources()
        .then(({ products, tutorials }) => {
          setAllProducts(products);
          setAllTutorials(tutorials);
          setQuestion({
            ...questionToUpdate,
            products: products.filter((product) =>
              questionToUpdate.products.includes(product._id)
            ),
            tutorials: tutorials.filter((tutorial) =>
              questionToUpdate.tutorials.includes(tutorial._id)
            ),
          });
          setIsLoaded(true);
        })
        .catch((erreur) => console.log(erreur));
    }
  }, [questionToUpdate, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setQuestion(DEFAULT_QUESTION);
      setIsLoaded(false);
    }
  }, [isOpen]);

  const saveQuestion = async () => {
    const questionToUpdate = {
      ...question,
      products: question.products.map((product) => product._id),
      tutorials: question.tutorials.map((tutorial) => tutorial._id),
    };
    console.log("modal - onSubmit");
    await onSubmit(questionToUpdate);
    console.log("modal - onClose");
    onClose();
  };

  const updateAssociatedProduct = (newList) => {
    setQuestion({
      ...question,
      products: newList,
    });
  };

  const updateAssociatedTutorial = (newList) => {
    setQuestion({
      ...question,
      tutorials: newList,
    });
  };

  const ActionButtons = () => (
    <>
      <Button onClick={() => saveQuestion()} style={{ color: "#90d058" }}>
        {question?._id ? "Modifier" : "Ajouter"}
      </Button>
      <Button onClick={() => onClose()} style={{ color: "#90d058" }}>
        Annuler
      </Button>
    </>
  );

  return (
    <MyModal
      title={
        question?._id ? "Modifier la question :" : "Ajouter une question :"
      }
      isOpen={isOpen}
      onClose={onClose}
      actions={<ActionButtons />}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        justifyContent="center"
        style={{ minHeight: "200px" }}
      >
        {!isLoaded ? (
          <CircularProgress
            disableShrink
            style={{ margin: "auto", color: "rgb(144, 208, 88)" }}
          />
        ) : (
          <>
            <MyTextField
              label="Question"
              onChange={(event) =>
                setQuestion({
                  ...question,
                  questionText: event.target.value,
                })
              }
              value={question.questionText}
              name="questionText"
            />
            <MyTextField
              label="Type d'outil"
              onChange={(event) =>
                setQuestion({
                  ...question,
                  toolType: event.target.value,
                })
              }
              value={question.toolType}
              name="toolType"
            />

            <Grid item container xs={12} sm={10}>
              <TextField
                label="Thème"
                select
                name="theme"
                fullWidth
                value={question.theme}
                onChange={(event) =>
                  setQuestion({ ...question, theme: event.target.value })
                }
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  inputMode: "numeric",
                }}
              >
                {THEMES.map((themeItem) => (
                  <MenuItem key={themeItem} value={themeItem}>
                    {themeItem}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <ChipSelector
              title="Solutions numériques associées"
              type="solution numérique"
              actualList={question.products}
              allList={allProducts}
              updateList={updateAssociatedProduct}
              labelKey="productName"
            />
            <ChipSelector
              title="Ressouces associées"
              type="ressource"
              actualList={question.tutorials}
              allList={allTutorials}
              updateList={updateAssociatedTutorial}
              labelKey="tutorialName"
            />
          </>
        )}
      </Grid>
    </MyModal>
  );
}
