import React from "react";
import { ResponsiveRadar } from "@nivo/radar";

export default ({ data }) => {
  const renamedKey = {
    "Gagnez en visibilité sur le web": "Visibilité",
    "Animez votre réseau": "Animation du réseau",
    "Travaillez collaborativement": "Travail collaboratif",
    "Financez vos projets": "Financement",
    "Gérez vos ressources humaines et financières": "Gestion des ressources",
    "Sécurisez votre association et suivez les réglementations": "Sécurité",
    "Pilotez la stratégie de votre transformation numérique":
      "Transformation numérique",
  };
  const formatedData = Object.keys(data).map((key) => {
    return {
      label: renamedKey[key] || key,
      score: data[key].toFixed(2) / 100,
    };
  });

  return (
    <>
      <ResponsiveRadar
        data={formatedData}
        keys={["score"]}
        indexBy="label"
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={10}
        dotSize={6}
        fillOpacity={0}
        valueFormat=">-.0%"
        maxValue={1}
        dotBorderWidth={2}
        gridShape="linear"
        isInteractive={true}
        colors={["#88CC17"]}
        blendMode="multiply"
        motionConfig="wobbly"
      />
      <div
        id="radar-for-pdf"
        style={{
          width: "595.28px",
          height: "250px",
          visibility: "hidden",
          zIndex: -1,
        }}
      >
        <ResponsiveRadar
          data={formatedData}
          keys={["score"]}
          indexBy="label"
          borderColor={{ from: "color" }}
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
          gridLabelOffset={10}
          dotSize={3}
          fillOpacity={0}
          valueFormat=">-.0%"
          maxValue={1}
          dotBorderWidth={2}
          gridShape="linear"
          isInteractive={false}
          colors={["#88CC17"]}
          blendMode="multiply"
          motionConfig="wobbly"
        />
      </div>
    </>
  );
};
