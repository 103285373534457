import { Typography } from "@material-ui/core";
import React from "react";

export function KeyResult({ value, label }) {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3">{value}</Typography>
      <Typography variant="subtitle1">{label}</Typography>
    </div>
  );
}
