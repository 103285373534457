import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function EnCoursIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41764 18.3894C9.80312 18.7538 10.4061 18.7538 10.7915 18.3894L13.577 15.7564L18.2092 15.7564C19.3138 15.7564 20.2092 14.861 20.2092 13.7564L20.2092 2C20.2092 0.895426 19.3138 -3.87549e-06 18.2092 -3.95049e-06L1.99988 -5.05104e-06C0.895312 -5.12603e-06 -0.000118163 0.895426 -0.000118287 1.99999L-0.000119611 13.7564C-0.000119735 14.861 0.89531 15.7564 1.99988 15.7564L6.63219 15.7564L9.41764 18.3894Z"
        fill="#9E989D"
      />
      <rect
        width="3.1933"
        height="1.60986"
        rx="0.804932"
        transform="matrix(0.789235 -0.614091 0.59099 0.806679 5.90845 11.0404)"
        fill="white"
      />
      <rect
        width="3.1933"
        height="1.60986"
        rx="0.804932"
        transform="matrix(0.789235 0.614092 0.590991 -0.806678 5.90845 4.9541)"
        fill="white"
      />
      <rect
        x="5.16968"
        y="7.45715"
        width="3.1579"
        height="1.62722"
        rx="0.813609"
        fill="white"
      />
      <rect
        x="10.9587"
        y="10.1693"
        width="3.25443"
        height="1.57898"
        rx="0.789489"
        transform="rotate(90 10.9587 10.1693)"
        fill="white"
      />
      <rect
        width="2.26061"
        height="0.807643"
        rx="0.403821"
        transform="matrix(0.859383 0.511333 -0.488753 0.872423 12.6633 8.99182)"
        fill="white"
      />
      <rect
        width="2.30364"
        height="0.792424"
        rx="0.396212"
        transform="matrix(0.33884 0.940844 -0.934002 0.357267 11.8406 9.88843)"
        fill="white"
      />
      <rect
        width="2.26061"
        height="0.807643"
        rx="0.403821"
        transform="matrix(0.859383 -0.511332 -0.488752 -0.872423 12.6633 6.82056)"
        fill="white"
      />
      <rect
        width="2.30364"
        height="0.792424"
        rx="0.396212"
        transform="matrix(0.33884 -0.940844 -0.934002 -0.357267 11.8406 5.92371)"
        fill="white"
      />
      <rect
        x="15.1697"
        y="8.54236"
        width="2.24329"
        height="0.813606"
        rx="0.406803"
        transform="rotate(-180 15.1697 8.54236)"
        fill="white"
      />
      <rect
        x="10.4329"
        y="3.42371"
        width="2.31184"
        height="0.78949"
        rx="0.394745"
        transform="rotate(90 10.4329 3.42371)"
        fill="white"
      />
    </SvgIcon>
  );
}
