import api from "./api";

const surveyAPI = {
  getSurvey: async () => {
    const response = await api.get(`/survey/`);
    return response.data;
  },
  updateSurvey: async ({ score, comment }) => {
    const response = await api.put("/survey/", { score, comment });
    return response.data;
  },
  getReport: async () => {
    const response = await api.get("/survey/report/");
    return response.data;
  },
  downloadSurveyResult: async () => {
    const surveyReport = await surveyAPI.getReport();
    downloadObjectAsJson(surveyReport && surveyReport.surveys, "NPS-results");
  },
};

function downloadObjectAsJson(exportObj, exportName) {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj, null, 2));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export default surveyAPI;
