import React from "react";
import { List, ListItem } from "@material-ui/core";
import { MainFormQuestionItem } from "./MainFormQuestionItem";
import { useQuickForm } from "../contexts/QuickFormContext";

export function MainFormQuestionList() {
  const { questions, answers } = useQuickForm();
  return (
    <List>
      {questions.map((question, index) => {
        return (
          <ListItem key={index}>
            <MainFormQuestionItem
              question={question}
              answer={answers.get(question._id)}
              key={index}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
